import React, { useCallback, useEffect, useState } from 'react'
import { path, pathOr, isEmpty, empty } from 'ramda'
import { Formik, FastField } from 'formik'
import * as yup from 'yup'
import { subscribe } from 'react-contextual'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash-es'
import {
  Modal,
  CurrencyField,
  TextField,
  FormField,
  Checkbox,
  CurrencyText,
  FormLoader,
  InfoBox,
  TextInput,
} from '../../../../views/components'
import { currencyUtils } from '../../../../utils'
import RefundProvider from '../../RefundProvider'
import styles from './RefundModal.module.scss'
import { walletApiEvents } from '../../../api/wallet'
import { $TSFixMe } from 'types/ts-migrate'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'

const validationSchema = yup.object().shape({
  amount: yup
    .number()
    .required()
    .test('amount', function (amount: $TSFixMe) {
      if (amount > this.parent.depositAccountBalance) {
        return this.createError({
          path: this.path,
          message: `Amount can't be greater than a balance of ${currencyUtils.formatCurrency(
            this.parent.depositAccountBalance,
          )}`,
        })
      }
      return true
    }),
  reference: yup.string().required(),
  isConfirmed: yup.bool().oneOf([true], 'Required!'),
})

const ButtonContainer = ({ children }: any) => <div className={styles['button-container']}>{children}</div>

const RefundModal = ({
  handleClose,
  requestRefund,
  isOpen,
  hasApiErrors,
  generalErrors,
  // fieldErrors,
  isSubmitting,
  isLoading,
  tenantId,
  tenantAccount,
  tenantName,
  tenantDetails,
  depositAccountBalance,
  asset,
  partyBankingErrors,
}: $TSFixMe) => {
  const dispatch = useDispatch()

  const _handleClose = useCallback(
    resetForm => {
      resetForm()
      handleClose()
    },
    [handleClose],
  )

  useEffect(() => {
    isOpen && dispatch(walletApiEvents.balance_request({ partyId: tenantId }))
  }, [isOpen, dispatch, tenantId])

  const [wasSubmitting, setWasSubmitting] = useState(false)

  useEffect(() => {
    if (isSubmitting) {
      setWasSubmitting(true)
    }
  }, [isSubmitting])

  const debouncedClose = useCallback(
    debounce(
      () => {
        handleClose()
        setWasSubmitting(false)
      },
      1000,
      { leading: false },
    ),
    [handleClose, setWasSubmitting],
  )

  useEffect(() => {
    if (!isSubmitting && wasSubmitting && !hasApiErrors) {
      debouncedClose()
    }
  }, [isSubmitting, wasSubmitting, debouncedClose, hasApiErrors])

  const partyId = tenantDetails?.id
  const bankingErrors = !partyBankingErrors ? [] : partyBankingErrors?.errors.map(error => error.message)

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={values => {
        const payload = { body: values, params: { partyId: values.partyId } }
        requestRefund(payload)
      }}
      initialValues={{
        amount: '',
        reference: '',
        isConfirmed: false,
        partyId,
        account: tenantAccount?.accountId,
        asset: asset?.name,
        depositAccountBalance,
      }}
    >
      {({ values, errors, touched, submitCount, handleSubmit, handleBlur, handleChange, setFieldValue, resetForm }) => {
        const confirmationCopy = `
          <p>I confirm that I'm about to refund <strong>${tenantName}</strong> with <strong>${currencyUtils.formatCurrency(
          parseInt(values.amount || '0'),
        )}</strong></p>
          <p>The refund will be paid directly to the following account:</p>
          <p>
            <strong>Bank:</strong> ${tenantDetails?.bank}<br />
            <strong>Account Type:</strong> ${tenantDetails?.accountType}<br />
            <strong>Account Number:</strong> *****${tenantDetails?.accountNumber?.substr(-4, 4)}<br />
            <strong>Account Holder:</strong> ${tenantDetails?.accountName}
          </p>
        `

        const formLoaderState = isLoading
          ? 'loading'
          : isSubmitting
          ? 'submitting'
          : (submitCount > 0 && !isEmpty(errors)) ||
            hasApiErrors ||
            (partyBankingErrors !== undefined && !empty(partyBankingErrors))
          ? 'error'
          : undefined

        return (
          <Modal
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            isOpen={isOpen}
            onClose={() => _handleClose(resetForm)}
          >
            <Modal.Title>Refund {tenantName}</Modal.Title>
            {bankingErrors.length > 0 && (
              <InfoBox type="error">
                <p>There are issues with the bank details. Please fix by editing the contact and try again.</p>
                <p>If you have already updated the contacts banking details, please try again in 5 minutes.</p>
                <ul>
                  {bankingErrors.map((err, i) => (
                    <li key={`${err} ${i}`}>{err as string}</li>
                  ))}
                </ul>
              </InfoBox>
            )}
            <Modal.Body>
              {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; buttonProps: { childr... Remove this comment to see the full error message */}
              <FormLoader
                buttonProps={{
                  children: 'Refund Deposit',
                  disabled: ['loading', 'error'].includes(formLoaderState as string),
                }}
                onSubmit={handleSubmit}
                state={formLoaderState}
                ButtonContainer={ButtonContainer}
                errorText={hasApiErrors ? pathOr('Oops, something went wrong.', [0], generalErrors) : 'Resolve errors'}
              >
                <FormField>
                  <FastField
                    name="amount"
                    render={({ field }: any) => (
                      <CurrencyField
                        {...field}
                        label={
                          <span>
                            Amount (Balance: <CurrencyText>{depositAccountBalance}</CurrencyText>)
                          </span>
                        }
                        error={path(['amount'], touched) && path(['amount'], errors)}
                        onChange={({ target }: any) => setFieldValue('amount', target.value)}
                      />
                    )}
                  />
                </FormField>
                <FormField>
                  <FastField
                    name="reference"
                    render={({ field }: any) => (
                      <TextField
                        {...field}
                        inputComponent={
                          <TextInput
                            type={TextFieldTypes.text}
                            name="reference"
                            value={values.reference}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        }
                        error={path(['reference'], touched) && path(['reference'], errors)}
                        label="Reference"
                      />
                    )}
                  />
                </FormField>
                <FormField>
                  <Checkbox
                    className={styles.confirmation}
                    name="isConfirmed"
                    label={<div dangerouslySetInnerHTML={{ __html: confirmationCopy }} />}
                    checked={values.isConfirmed}
                    onChange={(e: any) => setFieldValue('isConfirmed', e.checked)}
                    onBlur={handleBlur}
                    error={submitCount > 0 && path(['isConfirmed'], errors)}
                  />
                </FormField>
              </FormLoader>
            </Modal.Body>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default subscribe(
  [RefundProvider],
  ({
    refund: {
      handleClose,
      requestRefund,
      isOpen,
      hasApiErrors,
      generalErrors,
      // fieldErrors,
      isSubmitting,
      isLoading,
      tenantId,
      tenantAccount,
      tenantName,
      tenantDetails,
      depositAccountBalance,
      asset,
      partyBankingErrors,
    },
  }: $TSFixMe) => ({
    handleClose,
    requestRefund,
    isOpen,
    hasApiErrors,
    generalErrors,
    // fieldErrors,
    isSubmitting,
    isLoading,
    tenantId,
    tenantAccount,
    tenantName,
    tenantDetails,
    depositAccountBalance,
    asset,
    partyBankingErrors,
  }),
)(RefundModal)
