import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { customPropTypes } from '../../../../../utils'
import { CurrencyField, FundDistribution, Segment, TextField, TextInput } from '../../../../../components'
import styles from './PaymentAllocation.module.scss'
import { subscribe } from 'react-contextual'
import { InvoicesProvider } from '../../../../../providers'
import { useSelector } from 'react-redux'
import { agencyApiSelectors } from 'modules/api/agency'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'
import { partyApiSelectors } from 'modules/api/party'

const propTypes = {
  invoiceType: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  amount: PropTypes.string.isRequired,
  onAmountChange: PropTypes.func,
  onVatChange: PropTypes.func,
  amountVatApplied: PropTypes.bool,
  onNewBeneficiaryAdded: PropTypes.func,
  onBeneficiaryRemoved: PropTypes.func,
  onNewBeneficiaryAmountChange: PropTypes.func,
  onDepositBeneficiaryTransferChange: PropTypes.func.isRequired,
  onDepositManagedByChange: PropTypes.func,
  portfolioId: PropTypes.string,
  invoiceStatus: PropTypes.string,
  beneficiaries: PropTypes.arrayOf(
    PropTypes.shape({
      beneficiary: PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.shape({
          name: PropTypes.string,
          // @ts-expect-error ts-migrate(2322) FIXME: Type '(props: any, propName: any, componentName: a... Remove this comment to see the full error message
          partyId: PropTypes.oneOfType([customPropTypes.uuid, PropTypes.string]),
          partyTag: PropTypes.string,
          reference: PropTypes.string,
          amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          vat: PropTypes.bool,
        }),
      }),
    }),
  ),
  partyTags: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  propertyOwnerId: PropTypes.string,
  propertyOwnerName: PropTypes.string,
  propertyOwnerBankDetails: PropTypes.object,
  getOwnerAccountFromDraftInvoiceId: PropTypes.func,
  description: PropTypes.string,
  onDescriptionChange: PropTypes.func,
  unallocatedAmount: PropTypes.number.isRequired,
}

const defaultProps = {
  onAmountChange: (e: any) => e,
  onNewBeneficiaryAdded: (beneficiary: any) => beneficiary,
  beneficiaries: [],
}

class Amount extends Component {
  render(): null | React.ReactElement {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'amount' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { amount, invoiceType, onAmountChange, onVatChange, amountVatApplied } = this.props

    return (
      <Segment horizontal state={parseFloat(amount) > 0 ? 'default' : 'warning'}>
        <Segment.Label text={invoiceType} />
        <div className={styles.amount}>
          <CurrencyField
            includeVatControl
            name="amount"
            placeholder="Enter amount"
            onChange={onAmountChange}
            value={amount}
            onToggleChange={onVatChange}
            vatApplied={amountVatApplied}
          />
        </div>
      </Segment>
    )
  }
}

const PaymentAllocation = (props: any): React.ReactElement => {
  const {
    invoiceType,
    amount,
    onAmountChange,
    beneficiaries,
    invoiceStatus,
    onNewBeneficiaryAdded,
    onBeneficiaryRemoved,
    onNewBeneficiaryAmountChange,
    onDepositBeneficiaryTransferChange,
    onBeneficiariesOrderChange,
    errors,
    onVatChange,
    amountVatApplied,
    onBeneficiaryVatChange,
    partyTags,
    easyPayData,
    fetchEasyPayData,
    propertyOwnerId,
    propertyOwnerName,
    propertyOwnerBankDetails,
    description,
    onDescriptionChange,
    unallocatedAmount,
    onDepositManagedByChange,
    portfolioId,
    ...rest
  } = props

  const agencyName = useSelector(agencyApiSelectors.getCurrentAgencyName)
  const agencyBankDetails = useSelector(agencyApiSelectors.getCurrentAgencyBankDetails)
  return (
    <div className={styles.root}>
      <FundDistribution
        invoiceType={invoiceType}
        headerText="Payment allocation"
        beforeContent={
          <Segment className={styles['invoice-segment']}>
            <Amount
              // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
              amount={amount}
              invoiceType={invoiceType.name}
              onVatChange={onVatChange}
              invoiceStatus={invoiceStatus}
              onAmountChange={onAmountChange}
              amountVatApplied={amountVatApplied}
            />
            <Segment horizontal className={styles.description}>
              <TextField
                inputComponent={
                  <TextInput type={TextFieldTypes.text} value={description} onChange={onDescriptionChange} />
                }
                label="Description"
              />
            </Segment>
          </Segment>
        }
        onDepositManagedByChange={onDepositManagedByChange}
        balance={parseFloat(amount)}
        grossAmount={parseFloat(amount)}
        onNewBeneficiaryAdded={onNewBeneficiaryAdded}
        onBeneficiaryRemoved={onBeneficiaryRemoved}
        onNewBeneficiaryAmountChange={onNewBeneficiaryAmountChange}
        onDepositBeneficiaryTransferChange={onDepositBeneficiaryTransferChange}
        onBeneficiariesOrderChange={onBeneficiariesOrderChange}
        invoiceStatus={invoiceStatus}
        beneficiaries={beneficiaries}
        partySearchTags=""
        partyTags={partyTags}
        errors={errors}
        onVatChange={onBeneficiaryVatChange}
        easyPayData={easyPayData}
        fetchEasyPayData={fetchEasyPayData}
        agencyName={agencyName}
        agencyBankDetails={agencyBankDetails}
        propertyOwnerId={propertyOwnerId}
        propertyOwnerName={propertyOwnerName}
        propertyOwnerBankDetails={propertyOwnerBankDetails}
        unallocatedAmount={unallocatedAmount}
        portfolioId={portfolioId}
        {...rest}
      />
    </div>
  )
}

PaymentAllocation.propTypes = propTypes
PaymentAllocation.defaultProps = defaultProps

export default subscribe(
  [InvoicesProvider],
  ({ fetchEasyPayData, easyPayData, propertyOwnerId, propertyOwnerName, propertyOwnerBankDetails }: any) => ({
    fetchEasyPayData,
    easyPayData,
    propertyOwnerId,
    propertyOwnerName,
    propertyOwnerBankDetails,
  }),
)(PaymentAllocation)
