import React from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import { InfoBox } from '../../../../../../views/components'
// @ts-expect-error
import WarningSvg from '../../../../../../theme/icons-v2/Controls/Warning.svg'
import styles from './TerminationWarning.module.scss'

const propTypes = {
  draftInvoices: PropTypes.number,
  activeInvoices: PropTypes.number,
  openActiveInvoices: PropTypes.func,
  openDraftInvoices: PropTypes.func,
}

const invoiceLink = (
  count: any, // FIXME add type as number presumably
  label: any,
  handleActiveInvoices: any,
) => {
  return (
    <button className={styles.link} onClick={() => handleActiveInvoices()}>
      {count} {label} {pluralize('invoice', count)}
    </button>
  )
}

const TerminationWarning = ({ draftInvoices, activeInvoices, openActiveInvoices, openDraftInvoices }: any) => {
  return (
    <InfoBox type="error" className={styles.root}>
      <header>
        <img src={WarningSvg} alt="Warning" />
        <h3>
          <strong>WARNING!</strong> The lease will be terminated immediately.
        </h3>
      </header>
      <p>
        There {pluralize('are', draftInvoices)} {invoiceLink(draftInvoices, 'draft', openDraftInvoices)} and{' '}
        {invoiceLink(activeInvoices, 'outstanding', openActiveInvoices)} associated with this lease. They will{' '}
        <b>not be deleted</b> upon termination.
      </p>
      <p>
        By selecting to terminate this lease early, reOS will{' '}
        <b>disable all future smart invoices from being automatically generated.</b> You will still be able to ad-hoc
        invoice against the terminated lease as well as credit note or make payments on outstanding invoices.
      </p>
    </InfoBox>
  )
}

TerminationWarning.propTypes = propTypes

export default TerminationWarning
