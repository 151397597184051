import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './InfoList.module.scss'

const propTypes = {
  className: PropTypes.string,
  spacing: PropTypes.oneOf(['none', 'sm', 'md', 'lg']),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      onClick: PropTypes.func,
    }),
  ).isRequired,
}

const defaultProps = {}
const InfoList = ({ spacing = 'lg', items, className }: any) => {
  const classes = cx([styles.root, styles[`spacing--${spacing}`], className])
  return (
    items.length > 0 && (
      <ul className={classes}>
        {items.map(
          (item: any, i: any) =>
            item.value && (
              <li key={i}>
                <span className={styles.label}>{item.label}</span>
                {item.onClick ? (
                  <button className={styles.value} onClick={item.onClick}>
                    {item.value}
                  </button>
                ) : (
                  <span className={styles.value}>{item.value}</span>
                )}
              </li>
            ),
        )}
      </ul>
    )
  )
}

InfoList.propTypes = propTypes
InfoList.defaultProps = defaultProps

export default InfoList
