import React, { FC, ReactNode } from 'react'
import cx from 'classnames'
import { get } from 'lodash-es'
import { useHover } from 'react-use'
import Switch from '../../../../atoms/Switch/Switch'
import Tooltip from '../../../../atoms/Tooltip/Tooltip'
import CurrencyField from '../../../../molecules/CurrencyField/CurrencyField'
import styles from '../Beneficiary.module.scss'
import SettledBeneficiary from '../SettledBeneficiary/SettledBeneficiary'
import { Select } from 'views/components'
import dbStyles from './DepositBeneficiary.module.scss'
import { getDepositBeneficiaryBankDetails } from './depositBeneficiaryUtils'

type DepositBeneficiaryProps = {
  placeholder: string
  amountName?: string
  value: number | string
  index: number
  reference: string | ReactNode
  transfer: boolean
  errors?: string
  vatApplied: boolean
  disableTransferToggle?: boolean
  disableCurrencyField?: boolean
  isBeneficiarySettled: boolean
  amountPayable: number
  inputTooltipText: string
  isEditing?: boolean
  agencyName: string
  agencyBankDetails?: any
  isManagedByDisabled?: boolean
  managedByValue?: 'owner' | 'agency'
  propertyOwnerId?: string
  propertyOwnerName?: string
  propertyOwnerBankDetails?: any
  Meta?: FC
  includeVatCalculator?: boolean
  onNewBeneficiaryAmountChange: () => void
  onDepositManagedByChange: (index: number, option: { label: string; value: string }) => void
  onTransferChange: (index: number, transfer: boolean) => void
  onVatChange: () => void
}

const DepositBeneficiary: FC<DepositBeneficiaryProps> = props => {
  const {
    placeholder,
    amountName,
    value,
    index,
    reference,
    transfer,
    isEditing = false,
    vatApplied,
    includeVatCalculator = true,
    agencyName,
    agencyBankDetails,
    Meta,
    errors,
    disableTransferToggle = false,
    disableCurrencyField = true,
    isBeneficiarySettled,
    amountPayable,
    inputTooltipText,
    isManagedByDisabled,
    managedByValue = 'owner',
    propertyOwnerId,
    propertyOwnerBankDetails,
    onNewBeneficiaryAmountChange,
    onDepositManagedByChange,
    onTransferChange,
    onVatChange,
  } = props

  const classes = cx(styles.root, {
    [styles['is-editing']]: isEditing,
  })

  const options = isManagedByDisabled
    ? [{ label: 'Agency', value: 'agency' }]
    : [
        { label: 'Owner', value: 'owner' },
        { label: 'Agency', value: 'agency' },
      ]

  const managedByDescription = getDepositBeneficiaryBankDetails(
    managedByValue,
    agencyBankDetails,
    propertyOwnerId,
    propertyOwnerBankDetails,
  )

  const [details, isHovering] = useHover(
    <div className={styles.details}>
      <div>
        {disableTransferToggle ? (
          <strong>{transfer ? 'Managed by reOS' : `Self-managed by ${agencyName}`}</strong>
        ) : (
          <>
            <Switch
              className={styles['transfer-toggle']}
              label={transfer ? 'Managed by reOS' : 'Self-managed by'}
              checked={transfer}
              onChange={() => onTransferChange(index, !transfer)}
            />
            {!transfer && (
              <Select
                name="self-managed-by"
                isDisabled={isManagedByDisabled}
                className={dbStyles['managed-by-select']}
                options={options}
                onChange={(option: any) => {
                  onDepositManagedByChange(index, option)
                }}
                value={
                  isManagedByDisabled
                    ? { label: 'Agency', value: 'agency' }
                    : options.find(o => o.value === managedByValue)
                }
                styles={{
                  container: base => ({
                    ...base,
                    height: '20px',
                    lineHeight: '20px',
                  }),
                  valueContainer: base => ({
                    ...base,
                    display: 'flex',
                  }),
                  indicatorSeparator: () => ({}), // clear styles
                  control: base => ({
                    ...base,
                    border: 'none',
                    background: 'transparent !important',
                    height: '20px !important',
                    minHeight: 20,
                    lineHeight: '20px !important',
                  }),
                  dropdownIndicator: base => ({
                    ...base,
                    padding: '0',
                    display: isManagedByDisabled ? 'none' : 'flex',
                  }),
                  singleValue: base => ({
                    textDecoration: 'underline',
                  }),
                }}
              />
            )}
          </>
        )}
      </div>
      <div>{!transfer && managedByDescription}</div>
      {reference}
    </div>,
  )

  return (
    <li className={classes}>
      <Tooltip
        isOpen={!disableTransferToggle && isHovering}
        body={
          transfer
            ? 'The deposit amount is transferred to a reOS managed tenant deposit wallet.'
            : managedByValue === 'agency'
            ? 'The deposit amount is transferred to your external agency account.'
            : "The deposit amount is transferred to owner's account."
        }
      >
        {details}
      </Tooltip>
      {isBeneficiarySettled ? (
        <SettledBeneficiary amountPayable={amountPayable} />
      ) : (
        <div className={styles['input-wrapper']}>
          <CurrencyField
            disabled={disableCurrencyField}
            includeVatControl
            includeVatCalculator={includeVatCalculator}
            name={amountName}
            placeholder={placeholder}
            value={value}
            onChange={onNewBeneficiaryAmountChange}
            error={get(errors, 'amount')}
            onToggleChange={onVatChange}
            vatApplied={vatApplied}
            inputTooltipText={inputTooltipText}
          />
          {Meta && (
            <div className={styles.meta}>
              <Meta />
            </div>
          )}
        </div>
      )}
    </li>
  )
}

export default DepositBeneficiary
