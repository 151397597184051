import React, { useRef } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { isEmpty } from 'lodash-es'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import { FormField, FormLoader, TextField, TextInput } from '../../../../components'
import { userApiEvents } from '../../../../../modules/api/user'
import styles from './ForgotPasswordForm.module.scss'
import { formUtils } from '../../../../../utils'
import { apiStateSelectors } from '../../../../../modules/api/apiState'
import FormErrors from '../../../molecules/FormErrors/FormErrors'
import { TextFieldTypes } from 'components/atoms/TextField/text-field.types'

const validationSchema = yup.object().shape({
  email: yup.string().required('Email is required.').email('Email is invalid'),
})

const ButtonContainer = ({ children }: any): React.ReactElement => (
  <div className={styles['submit-button']}>{children}</div>
)

const ForgotPasswordForm = () => {
  const dispatch = useDispatch()
  const emailRef = useRef<any>()

  useEffectOnce(() => {
    emailRef.current?.focus()
  })

  const isSubmitting = useSelector(state =>
    apiStateSelectors.isLoading(state)([userApiEvents.resetPasswordLink_request]),
  )
  const getGeneralFormErrorsByEvent = useSelector(apiStateSelectors.getGeneralFormErrorsByEvent)
  const getFormFieldErrorsByEvent = useSelector(apiStateSelectors.getFormFieldErrorsByEvent)

  return (
    <div className={styles.root}>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{ email: '' }}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        onSubmit={values => dispatch(userApiEvents.resetPasswordLink_request(values))}
      >
        {({ errors, touched, handleChange, handleBlur, handleSubmit, submitCount, values }) => {
          const resetPasswordEvent = userApiEvents.resetPasswordLink_request(values)
          const getFieldError = formUtils.getFieldError(submitCount, touched, errors)
          const generalErrors = getGeneralFormErrorsByEvent(resetPasswordEvent)
          const fieldErrors = getFormFieldErrorsByEvent(resetPasswordEvent)
          const hasApiErrors = generalErrors.length > 0 || !isEmpty(fieldErrors)
          const formState = isSubmitting
            ? 'submitting'
            : (submitCount > 0 && !isEmpty(errors)) || hasApiErrors
            ? 'error'
            : undefined

          return (
            <>
              <h2>Forgot your password?</h2>
              <FormErrors errors={generalErrors} />
              <p>
                Enter your email address to reset your password. You may need to check your spam folder or unblock
                no-reply@reos.co.za.
              </p>
              {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; persistSuccessMessage: ... Remove this comment to see the full error message */}
              <FormLoader
                persistSuccessMessage
                onSubmit={handleSubmit}
                state={formState}
                ButtonContainer={ButtonContainer}
                successText="Check your email"
                errorText={hasApiErrors ? 'Problem signing in' : 'Resolve errors'}
                persistErrorMessage={!hasApiErrors}
              >
                <FormField>
                  <TextField
                    inputComponent={
                      <TextInput
                        type={TextFieldTypes.email}
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        ref={(ref: any) => {
                          emailRef.current = ref
                        }}
                      />
                    }
                    error={getFieldError('email')}
                  />
                </FormField>
              </FormLoader>
            </>
          )
        }}
      </Formik>
    </div>
  )
}

export default ForgotPasswordForm
