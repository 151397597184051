import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { subscribe } from 'react-contextual'
import { Heading, InfoList, Header } from '../../../../../views/components'
import EditIcon from '../../../../../views/components/atoms/Svgs/Edit'
import PortfoliosProvider from '../../../PortfoliosProvider'
import styles from './PropertyInfo.module.scss'
import { ContactsProvider } from 'modules/contacts'

const propTypes = {
  pathname: PropTypes.string,
  redirect: PropTypes.func,

  contactInfo: PropTypes.shape({
    name: PropTypes.string,
    lastModified: PropTypes.string,
    email: PropTypes.string,
    contactNumber: PropTypes.string,
    bank: PropTypes.node,
    accountNumber: PropTypes.string,
  }),
}

const PropertyInfo = ({ pathname, redirect, viewData, openContactFromNonContactsRoute }: any) => {
  const { owner } = viewData

  const infoListItem = useMemo(
    () => [
      {
        label: 'Name',
        value: owner.name,
        onClick: () => owner?.ownerId && openContactFromNonContactsRoute(owner.ownerId),
      },
      {
        label: 'Email',
        value: owner.email,
      },
      {
        label: 'Phone',
        value: owner.contactNumber,
      },
      {
        label: 'ID No.',
        value: owner.idNumber,
      },
    ],
    [owner],
  )

  const bankAccountItems = useMemo(
    () => [
      {
        label: 'Bank',
        value: owner.bank,
      },
      {
        label: 'Account',
        value: owner.accountNumber,
      },
    ],
    [owner],
  )

  return (
    <div className={styles.root}>
      <header>
        <button className={styles.edit} onClick={() => redirect(`${pathname}/property/edit`)}>
          <EditIcon />
        </button>
        <div>
          <Heading as="h2" className={styles.title}>
            {viewData.title}
          </Heading>
          <Heading as="h3" className={styles['sub-title']}>
            {viewData.subTitle}
          </Heading>
        </div>
      </header>
      <section>
        <Header className={styles.header} tag="h4" text="Owner information" />
        {/* @ts-expect-error ts-migrate(2786) FIXME: 'InfoList' cannot be used as a JSX component. */}
        <InfoList items={infoListItem} />
      </section>
      <section>
        <Header className={styles.header} tag="h4" text="Linked bank account" />
        {/* @ts-expect-error ts-migrate(2786) FIXME: 'InfoList' cannot be used as a JSX component. */}
        <InfoList spacing="none" items={bankAccountItems} />
      </section>
    </div>
  )
}

PropertyInfo.propTypes = propTypes

export default subscribe(
  [PortfoliosProvider, ContactsProvider],
  ({ location, redirect }: any, { openContactFromNonContactsRoute }) => ({
    pathname: location.pathname,
    redirect,
    openContactFromNonContactsRoute,
  }),
)(PropertyInfo)
