import { morphism } from 'morphism'
import { get } from 'lodash-es'
import { currencyUtils } from '../../../utils'
import { commissionUndertakings, paymentRuleClearanceTypes } from './constants'
import { toLower, uniq } from 'ramda'
import { $TSFixMe } from 'types/ts-migrate'
import { TPortfolioSummariesResponse } from './types'

const { toCents, fromCents } = currencyUtils

const transformPaymentRuleClearance = (clearance: any, convertToCents = true): $TSFixMe => {
  switch (clearance.type) {
    case paymentRuleClearanceTypes.fixed:
      return {
        ...clearance,
        value: {
          ...clearance.value,
          grossAmount: convertToCents
            ? toCents(clearance.value.grossAmount).getAmount()
            : fromCents(clearance.value.grossAmount),
        },
      }
    default:
      return clearance
  }
}

const transformCommissionFee = (fee: $TSFixMe, convertToCents = true) => {
  switch (get(fee, 'ofRentalAmount.type')) {
    case commissionUndertakings.fixed:
      return {
        type: fee.ofRentalAmount.type,
        netAmount: {
          value: convertToCents
            ? toCents(fee.ofRentalAmount.value.netAmount).getAmount()
            : fromCents(fee.ofRentalAmount.value.netAmount),
          vatable: get(fee, 'ofRentalAmount.value.vatable', false),
        },
        splits: fee.splits || [],
      }
    case commissionUndertakings.variable:
      return {
        type: fee.ofRentalAmount.type,
        percentage: {
          value: get(fee, 'ofRentalAmount.value.percentage', 0),
          vatable: get(fee, 'ofRentalAmount.value.vatable', false),
        },
        splits: fee.splits || [],
      }
    default:
      return fee
  }
}

const getPortfolioResponseSchema = {
  id: 'id',
  createdByUserId: 'createdByUserId',
  updatedByUserId: 'updatedByUserId',
  createdAt: 'createdAt',
  deletedAt: 'deletedAt',
  updatedAt: 'updatedAt',
  tags: 'tags',
  propertyId: 'propertyId',
  /** @todo handle unmanaged */
  contractContainer: ({ contractContainer }: any) => {
    const type = get(contractContainer, 'type', '')
    const terms = get(contractContainer, 'value.terms', {})
    return (
      contractContainer && {
        type,
        value: {
          terms: {
            keyDepositAmount: toCents(get(terms, 'keyDepositAmount', 0)).getAmount(),
            serviceDepositAmount: toCents(get(terms, 'serviceDepositAmount', 0)).getAmount(),
            damageDepositAmount: toCents(get(terms, 'damageDepositAmount', 0)).getAmount(),
            monthlyRentAmount: toCents(get(terms, 'monthlyRentAmount', 0)).getAmount(),
            firstMonthRentAmount: toCents(get(terms, 'firstMonthRentAmount', 0)).getAmount(),
            leaseFee: {
              netAmount: toCents(get(terms, 'leaseFee.netAmount', 0)).getAmount(),
              vatable: get(terms, 'leaseFee.vatable', false),
            },
            applicationFee: {
              netAmount: toCents(get(terms, 'applicationFee.netAmount', 0)).getAmount(),
              vatable: get(terms, 'applicationFee.vatable', false),
            },
            depositHeldByLandlord: get(terms, 'depositHeldByLandlord', false),
          },
        },
      }
    )
  },
  parties: 'parties',
  invoiceTemplates: ({ invoiceTemplates }: any): $TSFixMe => {
    return invoiceTemplates?.map((template: any) => ({
      ...template,
      netAmount: toCents(get(template, 'netAmount', 0)).getAmount(),

      paymentRules: template.paymentRules.map((rule: any) => ({
        beneficiary: {
          ...rule.beneficiary,
          value: {
            ...rule.beneficiary.value,
            amount: toCents(rule.beneficiary.value.amount).getAmount(),
          },
        },

        clearance: transformPaymentRuleClearance(rule.clearance),
      })),
    }))
  },
  agents: 'agents',
  leaseIdentifier: 'leaseIdentifier',
  leaseTerms: 'leaseTerms',
  settings: 'settings',
  segments: ({ segments = [] }) => uniq(segments),
  commission: ({ commission }: any) =>
    commission && {
      managementFee: transformCommissionFee(get(commission, 'managementFee')),
      procurementFee: transformCommissionFee(get(commission, 'procurementFee')),
    },
  renewal: ({ renewal }: any) =>
    renewal && {
      newRentAmount: toCents(renewal.newRentAmount).getAmount(),
      newCommission: {
        managementFee: transformCommissionFee(get(renewal, 'newCommission.managementFee')),
        procurementFee: transformCommissionFee(get(renewal, 'newCommission.procurementFee')),
      },
      depositTopUp: toCents(renewal.depositTopUp).getAmount(),
      renewalFee: toCents(renewal.renewalFee).getAmount(),
      renewalFeeVatable: renewal.renewalFeeVatable,
      leaseRenewsAt: renewal.leaseRenewsAt,
      newLeaseTerms: renewal.newLeaseTerms,
    },
  terminationFields: 'terminationFields',
  status: ({ status = 'draft' }) => toLower(status),
  applications: 'applications',
}

export const transformGetPortfolioResponse = (source: any): $TSFixMe => morphism(getPortfolioResponseSchema, source)

const getSummarySchema = {
  agencyId: ({ portfolioSummaryRow }: TPortfolioSummariesResponse) => portfolioSummaryRow?.agencyId,
  portfolioId: ({ portfolioSummaryRow }: TPortfolioSummariesResponse) => portfolioSummaryRow?.portfolioId,
  propertyId: ({ portfolioSummaryRow }: TPortfolioSummariesResponse) => portfolioSummaryRow?.propertyId,
  mainText: ({ portfolioSummaryRow }: TPortfolioSummariesResponse) => portfolioSummaryRow?.mainText,
  secondaryText: ({ portfolioSummaryRow }: TPortfolioSummariesResponse) => portfolioSummaryRow?.secondaryText,
  primaryOwnerId: ({ portfolioSummaryRow }: TPortfolioSummariesResponse) => portfolioSummaryRow?.primaryOwnerId,
  primaryOwnerName: ({ portfolioSummaryRow }: TPortfolioSummariesResponse) => portfolioSummaryRow?.primaryOwnerName,
  primaryTenantId: ({ portfolioSummaryRow }: TPortfolioSummariesResponse) => portfolioSummaryRow?.primaryTenantId,
  primaryTenantName: ({ portfolioSummaryRow }: TPortfolioSummariesResponse) => portfolioSummaryRow?.primaryTenantName,
  tags: ({ portfolioSummaryRow }: TPortfolioSummariesResponse) => portfolioSummaryRow?.tags,
  landlords: ({ portfolioSummaryRow }: TPortfolioSummariesResponse) => portfolioSummaryRow?.landlords,
  tenants: ({ portfolioSummaryRow }: TPortfolioSummariesResponse) => portfolioSummaryRow?.tenants,
  segments: ({ portfolioSummaryRow }: TPortfolioSummariesResponse) => uniq(portfolioSummaryRow?.segments),
  leaseTerms: ({ portfolioSummaryRow }: TPortfolioSummariesResponse) => portfolioSummaryRow?.leaseTerms,
  renewal: ({ portfolioSummaryRow }: TPortfolioSummariesResponse) => portfolioSummaryRow?.renewal,
  deletedAt: ({ portfolioSummaryRow }: TPortfolioSummariesResponse) => portfolioSummaryRow?.deletedAt,
  status: ({ portfolioSummaryRow }: TPortfolioSummariesResponse) =>
    portfolioSummaryRow.status ? toLower(portfolioSummaryRow.status) : 'draft',
  terminationFields: 'terminationFields',
  applications: 'applications',
}

export const transformGetSummariesResponse = (source: any): $TSFixMe => morphism(getSummarySchema, source)

export const getListResponseSchema = {
  mainText: 'mainText',
  secondaryText: 'secondaryText',
  propertyId: 'propertyId',
  portfolios: ({ portfolios }: any) => transformGetPortfolioResponse(portfolios),
}

export const transformGetListResponse = (source: any): $TSFixMe => morphism(getListResponseSchema, source)

const amendManagedContractRequestSchema = {
  type: 'type',
  value: ({ value: { terms } }: any) => {
    return {
      terms: {
        keyDepositAmount: fromCents(get(terms, 'keyDepositAmount', 0)),
        serviceDepositAmount: fromCents(get(terms, 'serviceDepositAmount', 0)),
        damageDepositAmount: fromCents(get(terms, 'damageDepositAmount', 0)),
        monthlyRentAmount: fromCents(get(terms, 'monthlyRentAmount', 0)),
        firstMonthRentAmount: fromCents(get(terms, 'firstMonthRentAmount', 0)),
        leaseFee: {
          netAmount: fromCents(get(terms, 'leaseFee.netAmount', 0)),
          vatable: get(terms, 'leaseFee.vatable', false),
        },
        applicationFee: {
          netAmount: fromCents(get(terms, 'applicationFee.netAmount', 0)),
          vatable: get(terms, 'applicationFee.vatable', false),
        },
      },
    }
  },
}

export const transformAmendManagedContractRequest = (source: $TSFixMe) =>
  morphism(amendManagedContractRequestSchema, source)

const amendUnmanagedContractRequestSchema = {
  type: 'type',
  value: ({ value: { terms } }: $TSFixMe) => {
    return {
      terms: {
        keyDepositAmount: fromCents(get(terms, 'keyDepositAmount', 0)),
        serviceDepositAmount: fromCents(get(terms, 'serviceDepositAmount', 0)),
        damageDepositAmount: fromCents(get(terms, 'damageDepositAmount', 0)),
        monthlyRentAmount: fromCents(get(terms, 'monthlyRentAmount', 0)),
        firstMonthRentAmount: fromCents(get(terms, 'firstMonthRentAmount', 0)),
        leaseFee: fromCents(get(terms, 'leaseFee.netAmount', 0)),
        applicationFee: fromCents(get(terms, 'applicationFee.netAmount', 0)),
        depositHeldByLandlord: get(terms, 'depositHeldByLandlord', true),
        collectCommissionFromLandlord: get(terms, 'collectCommissionFromLandlord', false),
        invoiceDifferenceToLandlord: get(terms, 'invoiceDifferenceToLandlord', true),
      },
    }
  },
}

export const transformAmendUnmanagedContractRequest = (source: $TSFixMe) =>
  morphism(amendUnmanagedContractRequestSchema, source)

const transformCommissionRequest = (commission: $TSFixMe) => {
  if (!commission) {
    return null
  }

  const value =
    commission.type === commissionUndertakings.fixed
      ? { netAmount: fromCents(commission?.netAmount?.value || 0), vatable: commission?.netAmount?.vatable || false }
      : { percentage: commission?.percentage?.value || 0, vatable: commission?.percentage?.vatable || false }

  return {
    ofRentalAmount: {
      type: commission.type,
      value,
    },
    splits:
      commission?.netAmount?.value > 0 || commission?.percentage?.value > 0
        ? commission.splits.map((split: $TSFixMe) => ({
            agentPartyId: split?.agent?.value,
            splitPercentage: split?.splitPercentage,
          }))
        : [],
  }
}

const amendCommissionSchema = {
  managementFee: ({ managementFee }: $TSFixMe) => managementFee && transformCommissionRequest(managementFee),
  procurementFee: ({ procurementFee }: $TSFixMe) => procurementFee && transformCommissionRequest(procurementFee),
}

export const transformAmendCommissionRequest = (source: any): $TSFixMe => morphism(amendCommissionSchema, source)

const invoiceTemplateSchema = {
  category: 'category',
  invoicePartyId: 'invoicePartyId', // Either Tenant or Landlord.
  invoicePartyAccountType: 'invoicePartyAccountType', // partyApiConstants.accountTypes
  interval: 'interval', // BeginningOfMonth, EndOfMonth, MidMonth
  vatable: 'vatable',
  autoDeliver: 'autoDeliver',
  netAmount: ({ netAmount }: any) => fromCents(netAmount),
  paymentRules: ({ paymentRules }: any) =>
    paymentRules.map(({ beneficiary, clearance }: any) => {
      const pr = {
        beneficiary: {
          ...beneficiary,
          value: {
            ...beneficiary.value,
            amount: fromCents(beneficiary.value.amount),
          },
        },
        clearance: transformPaymentRuleClearance(clearance, false),
      }

      return pr
    }),
  description: 'description',
}

export const transformInvoiceTemplate = (source: any): $TSFixMe => morphism(invoiceTemplateSchema, source)

const renewalRequestSchema = {
  newRentAmount: ({ newRentAmount }: any) => fromCents(newRentAmount),
  newCommission: ({ newCommission }: $TSFixMe) => ({
    managementFee: transformCommissionRequest(get(newCommission, 'managementFee')),
    procurementFee: transformCommissionRequest(get(newCommission, 'procurementFee')),
  }),
  depositTopUp: ({ depositTopUp }: any) => fromCents(depositTopUp),
  renewalFee: ({ renewalFee }: any) => fromCents(renewalFee),
  renewalFeeVatable: 'renewalFeeVatable',
  leaseRenewsAt: 'leaseRenewsAt',
  newLeaseTerms: 'newLeaseTerms',
}

export const transformRenewRequest = (source: any): $TSFixMe => morphism(renewalRequestSchema, source)

/**
 * the original request payload is passed back into the response because the backend doesn't return anything
 * @todo refactor all commission transformations, it's getting lank confusing
 */
const transformRenewalRequestToResponse = (commission: $TSFixMe) => {
  if (!commission) {
    return null
  }

  return {
    ...commission,
    splits: commission.splits.map((split: $TSFixMe) => ({
      agentPartyId: split?.agent?.value,
      splitPercentage: split?.splitPercentage,
    })),
  }
}

const renewalResponseSchema = {
  newRentAmount: 'newRentAmount',
  newCommission: ({ newCommission }: $TSFixMe) => ({
    managementFee: transformRenewalRequestToResponse(get(newCommission, 'managementFee', false)),
    procurementFee: transformRenewalRequestToResponse(get(newCommission, 'procurementFee', false)),
  }),
  depositTopUp: 'depositTopUp',
  leaseRenewsAt: 'leaseRenewsAt',
  newLeaseTerms: 'newLeaseTerms',
  renewalFee: 'renewalFee',
  renewalFeeVatable: 'renewalFeeVatable',
}

export const transformRenewalResponse = (source: $TSFixMe) => morphism(renewalResponseSchema, source)

// @todo Update these to include VAT

const applicationsResponseSchema = {
  type: 'type',
  payload: ({ payload }: any) => ({
    portfolioId: payload.portfolioId,
    applications: payload.applications.map((application: any) => ({
      ...application,
      applicationFee: toCents(application.applicationFee).getAmount(),
    })),
  }),
}

export const transformApplicationsResponse = (source: any): $TSFixMe => morphism(applicationsResponseSchema, source)

const addApplicationRequestSchema = {
  partyId: 'partyId',
  applicationFee: ({ applicationFee }: any) => fromCents(applicationFee),
}

export const transformAddApplicationRequest = (source: any): $TSFixMe => morphism(addApplicationRequestSchema, source)

const updateApplicationRequestSchema = {
  applicationFee: ({ applicationFee }: any) => fromCents(applicationFee),
  vat: 'vat',
  partyId: 'partyId',
}

export const transformUpdateApplicationRequest = (source: any): $TSFixMe =>
  morphism(updateApplicationRequestSchema, source)
