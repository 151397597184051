import React from 'react'
// import PropTypes from 'prop-types'
import styles from './SmartInvoices.module.scss'
import { currencyUtils } from '../../../../../../utils'
import { Header } from '../../../../../../views/components'

const propTypes = {}

const defaultProps = {}

const SmartInvoices = ({ invoices }: any) => {
  return (
    invoices.length > 0 && (
      <section className={styles.root}>
        <Header className={styles.header} tag="h4" text="Smart Invoices" />
        <ul>
          {invoices.map((inv: any, i: any) => (
            <li key={i}>
              <span>{inv.label}</span>
              <span>{inv.value === 'Set on send' ? inv.value : currencyUtils.formatCurrency(inv.value)}</span>
            </li>
          ))}
        </ul>
      </section>
    )
  )
}

SmartInvoices.propTypes = propTypes
SmartInvoices.defaultProps = defaultProps

export default SmartInvoices
