const { REACT_APP_API_URL = '' } = process.env

export const NAMESPACE = 'portfolio'

export const SEARCH_TAGS = ['in progress', 'approved', 'declined', 'pending approval']

export const leaseTypes = {
  managed: 'ManagedContract',
  unmanaged: 'UnmanagedContract',
}

export const paymentRuleClearanceTypes = {
  fixed: 'FixedClearance_v1',
  variable: 'VariableClearance_v1',
  notset: 'NotSetClearance_v1',
}

export const commissionUndertakings = {
  fixed: 'FixedUndertaking_v1',
  variable: 'VariableUndertaking_v1',
}

export const rentalInvoicePaymentReferences = {
  commission: 'Commission',
  rentalIncome: 'Rental Income',
}

const BASE = `${REACT_APP_API_URL}/portfolios`
const ENTITY = `${BASE}/:id`
const BASE_V2 = `${REACT_APP_API_URL}/portfolios/v2`
const ENTITY_V2 = `${BASE_V2}/:id`

export const ENDPOINTS = {
  BASE,
  BASE_V2,
  SUMMARIES: `${BASE}/v2/summaries`,
  FETCH_PORTFOLIO: `${ENTITY}/entity`,
  AMEND_PROPERTY: `${ENTITY_V2}/property`,
  AMEND_TERMS: `${ENTITY_V2}/lease_terms`,
  AMEND_CONTRACT: `${ENTITY_V2}/contract`,
  AMEND_SETTINGS: `${ENTITY_V2}/settings`,
  AMEND_COMMISSION: `${ENTITY_V2}/commission`,
  CREATE_INVOICE_TEMPLATE: `${ENTITY_V2}/invoice_templates`,
  UPDATE_INVOICE_TEMPLATE: `${ENTITY_V2}/invoice_templates/:invoiceId`,
  REMOVE_INVOICE_TEMPLATE: `${ENTITY_V2}/invoice_templates/:invoiceId`,
  INVOICE_SCHEDULES: `${ENTITY}/invoice_schedules`,
  REQUEST_APPROVAL: `${ENTITY}/request_approval`,
  APPROVE: `${ENTITY_V2}/approve`,
  DECLINE: `${ENTITY}/decline`,
  SEARCH: `${BASE}/search`,
  FETCH_TERMINATION_REASONS: `${BASE}/termination_reasons`,
  TERMINATE_APPROVED_LEASE: `${ENTITY_V2}/terminate`,
  DELETE_DRAFT_LEASE: `${ENTITY_V2}`,
  RENEW: `${ENTITY_V2}/renew`,
  AMEND_SEGMENTS: `${ENTITY}/segments`,
  BULK_INVOICES_CSV_TEMPLATE: `${BASE}/bulk_invoices_csv_template`,
  UPDATE_TENANT_PARTIES: `${ENTITY}/tenant_parties`,
  UPDATE_OWNER_PARTIES: `${ENTITY}/owner_parties`,
  GET_STATS_PORTFOLIO: `${BASE}/stats`,
  GET_STATUS: `${BASE}/get_status`,
  LEASE_APPLICATIONS: `${ENTITY}/applications`,
  DELETE_APPLICATION: `${ENTITY}/applications/:applicationId`,
  UPDATE_APPLICATION: `${ENTITY}/applications/:applicationId`,
  ACCEPT_APPLICATION: `${ENTITY}/applications/:applicationId/accept`,
}

export const DEBOUNCE_TIME = 1000
