import React, { FC } from 'react'
import { Header } from 'views/components'
import HotIconFill from 'views/components/atoms/Icons/Other/HotFill'
import styles from './IntegrationsContent.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { integrationsApiEvents, integrationsApiSelectors } from 'modules/api/integrations'
import IntegrationCard from '../IntegrationCard/IntegrationCard'
import { useEffectOnce } from 'react-use'
import DownloadTPNReport from '../Reports/DownloadTPNReport'
import { userApiSelectors } from 'modules/api/user'

type TStaticIntegration = {
  Component: JSX.Element
}
const IntegrationsContent: FC = () => {
  const dispatch = useDispatch()

  useEffectOnce(() => {
    dispatch(integrationsApiEvents.integrations_request())
  })

  const integrationsList = useSelector(integrationsApiSelectors.getIntegrationsList)
  const testUserIntegrationsList = useSelector(integrationsApiSelectors.getTestUserIntegrationsList)
  const combinedIntegrationsList = integrationsList.concat(testUserIntegrationsList)
  const sortedIntegrationsList = integrationsList.sort((a, b) => a.orderNo - b.orderNo)
  const sortedCombinedIntegrationsList = combinedIntegrationsList.sort((a, b) => a.orderNo - b.orderNo)
  const staticIntegrations: TStaticIntegration[] = [
    {
      Component: <DownloadTPNReport name="TPN Report" short="Download the latest TPN Report" />,
    },
  ]

  const isValidSupportStaff = useSelector(userApiSelectors.isValidSupportStaff)

  return (
    <div className={styles.root}>
      <Header icon={<HotIconFill />} text="Integrations" />
      <div className={styles.integrations}>
        {staticIntegrations.map((item, i: number) => (
          <React.Fragment key={i}>{item.Component}</React.Fragment>
        ))}
        {isValidSupportStaff
          ? sortedCombinedIntegrationsList.map((item, i: number) => <IntegrationCard key={i} integration={item} />)
          : sortedIntegrationsList.map((item, i: number) => <IntegrationCard key={i} integration={item} />)}
      </div>
    </div>
  )
}

export default IntegrationsContent
