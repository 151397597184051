import React from 'react'
import { pathOr } from 'ramda'
import { FormField, Select, TextField, TextInput } from '../../../components'
import { formUtils } from '../../../../utils'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'

const BankDetails = (props: any) => {
  const {
    values,
    valueRoot,
    handleChange,
    handleBlur,
    setFieldValue,
    accountDescription,
    errors,
    touched,
    submitCount,
    disableFields,
  } = props

  const fieldName = (field: any) => {
    const path = valueRoot ? [valueRoot, field] : [field]
    return path.join('.')
  }

  const selectHandler =
    (field: any) =>
    (e: any): void =>
      setFieldValue(fieldName(field), e.value)

  const getFieldError = formUtils.getFieldError(submitCount, touched, errors)

  const bankOptions = [
    {
      value: 'FNB',
      label: 'FNB',
    },
    {
      value: 'Standard Bank',
      label: 'Standard Bank',
    },
    {
      value: 'Capitec',
      label: 'Capitec',
    },
    {
      value: 'ABSA',
      label: 'ABSA',
    },
    {
      value: 'Nedbank',
      label: 'Nedbank',
    },
    {
      value: 'Investec',
      label: 'Investec',
    },
    {
      value: 'Bidvest',
      label: 'Bidvest',
    },
    {
      value: 'African Bank',
      label: 'African Bank',
    },
    {
      value: 'Mercantile',
      label: 'Capitec Business',
    },
    {
      value: 'Discovery',
      label: 'Discovery',
    },
    {
      value: 'Sasfin',
      label: 'Sasfin',
    },
    {
      value: 'TymeBank',
      label: 'TymeBank',
    },
    {
      value: 'Bank of Athens',
      label: 'Access Bank',
    },
    {
      value: 'Old Mutual',
      label: 'Old Mutual',
    },
    {
      value: 'Albaraka',
      label: 'Albaraka',
    },
    {
      value: 'Bank Zero',
      label: 'Bank Zero',
    },
  ]

  const accountTypeOptions = [
    {
      value: 'Cheque',
      label: 'Cheque',
    },
    {
      value: 'Savings',
      label: 'Savings',
    },
    {
      value: 'Bond',
      label: 'Bond',
    },
  ]

  const getSelectValue = (options: any, key: any) => options.find((o: any) => o.label === values[key])
  const getFieldValue = (name: any) => pathOr('', [name], values)

  return (
    <div>
      <div className="rbn--row">
        <div className="rbn--col-md-4">
          <FormField>
            <Select
              isSearchable
              options={bankOptions}
              name={fieldName('bank')}
              label="Bank"
              isDisabled={disableFields}
              error={getFieldError(fieldName('bank'))}
              value={getSelectValue(bankOptions, 'bank')}
              onBlur={handleBlur}
              onChange={selectHandler('bank')}
            />
          </FormField>
        </div>
      </div>
      <div className="rbn--row">
        <div className="rbn--col-md-2">
          <FormField>
            <Select
              options={accountTypeOptions}
              name={fieldName('accountType')}
              label="Account Type"
              isDisabled={disableFields}
              error={getFieldError(fieldName('accountType'))}
              value={getSelectValue(accountTypeOptions, 'accountType')}
              onBlur={handleBlur}
              onChange={selectHandler('accountType')}
            />
          </FormField>
        </div>
        <div className="rbn--col-md-4">
          <FormField>
            <TextField
              inputComponent={
                <TextInput
                  type={TextFieldTypes.text}
                  name={fieldName('accountNumber')}
                  placeholder="e.g. 0700123"
                  value={getFieldValue('accountNumber')}
                  disabled={disableFields}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              label="Account Number"
              error={getFieldError(fieldName('accountNumber'))}
            />
          </FormField>
        </div>
        {accountDescription && (
          <div className="rbn--col-md-3">
            <FormField>
              <TextField
                inputComponent={
                  <TextInput
                    type={TextFieldTypes.text}
                    name={fieldName('accountName')}
                    placeholder="e.g. Mr. B Wayne"
                    value={getFieldValue('accountName')}
                    disabled={disableFields}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                }
                label="Account Holder"
                error={getFieldError(fieldName('accountName'))}
              />
            </FormField>
          </div>
        )}
      </div>
    </div>
  )
}

export default BankDetails
