import React from 'react'
import PropTypes from 'prop-types'
import { Header, InfoList } from '../../../../../../views/components'
import EditIcon from '../../../../../../views/components/atoms/Svgs/Edit'
import styles from './PortfolioDetails.module.scss'

const propTypes = {
  portfolioLoaded: PropTypes.bool,
  leaseDetails: PropTypes.arrayOf(PropTypes.object),
  redirect: PropTypes.func,
  leaseUrl: PropTypes.string,
}

const defaultProps = {}

const PortfolioDetails = ({ portfolioLoaded, leaseDetails, redirect, leaseUrl }: any) => {
  return (
    <section className={styles.root}>
      <Header
        className={styles.header}
        tag="h4"
        text="Lease details"
        trailingIcon={
          portfolioLoaded && (
            <button className={styles.edit} onClick={() => redirect(leaseUrl)}>
              <EditIcon />
            </button>
          )
        }
      />
      {/* @ts-expect-error ts-migrate(2786) FIXME: 'InfoList' cannot be used as a JSX component. */}
      <InfoList spacing="sm" items={leaseDetails} />
    </section>
  )
}

PortfolioDetails.propTypes = propTypes
PortfolioDetails.defaultProps = defaultProps

export default PortfolioDetails
