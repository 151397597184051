import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { partyApiConstants, partyApiSelectors } from '../../../../../modules/api/party'
import { FormLoader, Heading, InfoBox } from '../../..'
import BankDetails from '../../FormGroups/BankDetails'
import EntityDetails from '../../FormGroups/EntityDetails'
// import AddressFormGroup from '../../FormGroups/AddressFormGroup'
import styles from './PartyFormHelper.module.scss'
import { isEmpty, mergeRight } from 'ramda'
import FormErrors from '../../../molecules/FormErrors/FormErrors'
import { useSelector } from 'react-redux'
import { $TSFixMe } from 'types/ts-migrate'
import AddressFormGroup from '../../FormGroups/AddressFormGroup'

const propTypes = {
  validationSchema: PropTypes.object,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  isSubmitting: PropTypes.bool,
  isLoading: PropTypes.bool,
  getGeneralErrors: PropTypes.func,
  getFieldErrors: PropTypes.func,
  allowSearchable: PropTypes.bool,
  getButtonText: PropTypes.func,
  isDisabled: PropTypes.bool,
}

const ButtonContainer = ({ children }: any) => <div className={styles.footer}>{children}</div>

const PartyEntityForm = ({
  validationSchema,
  onSubmit,
  initialValues,
  isSubmitting,
  getGeneralErrors,
  getFieldErrors,
  allowSearchable,
  getButtonText,
  isDisabled,
}: $TSFixMe) => {
  const [selectedPartyId, setSelectedPartyId] = useState(null)

  const getPartyValues = useSelector(partyApiSelectors.getPartyDetailsById)

  return (
    <Formik
      validateOnBlur
      validateOnChange
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      initialTouched={initialValues}
      onSubmit={(values, { resetForm }) => {
        onSubmit({ ...values, partyType: partyApiConstants.PARTY_TYPES.COMPANY }, resetForm)
        setSelectedPartyId(null)
        resetForm()
      }}
    >
      {({
        handleSubmit,
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
        touched,
        resetForm,
        submitCount,
        setValues,
      }) => {
        const generalErrors = getGeneralErrors(values)
        const fieldErrors = getFieldErrors(values)
        const hasApiErrors = generalErrors.length > 0 || !isEmpty(fieldErrors)

        const formLoaderState = isSubmitting
          ? 'submitting'
          : (submitCount > 0 && !isEmpty(errors)) || hasApiErrors
          ? 'error'
          : undefined

        const handlePartySelect = (id: any): void => {
          resetForm()
          setValues(getPartyValues(id))
          setSelectedPartyId(id)
        }

        const isCurrentContactAnAgency = values.tags.includes('Agency')

        return (
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; className: any; onSubmi... Remove this comment to see the full error message
          <FormLoader
            className={styles.form}
            onSubmit={handleSubmit}
            state={formLoaderState}
            buttonProps={{ children: typeof getButtonText === 'function' ? getButtonText(values) : 'Save' }}
            ButtonContainer={ButtonContainer}
            persistErrorMessage={!hasApiErrors && !isEmpty(errors)}
            errorText={hasApiErrors ? 'Oops, something went wrong' : 'Resolve errors'}
            isDisabled={isDisabled || isCurrentContactAnAgency}
          >
            {isCurrentContactAnAgency && <InfoBox>Please contact support to update business details</InfoBox>}
            <div className={styles.body}>
              <FormErrors errors={generalErrors} />
              <section>
                <EntityDetails
                  companiesOnly
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleSelect={({ id }) => handlePartySelect(id)}
                  setFieldValue={setFieldValue}
                  errors={mergeRight(errors, fieldErrors)}
                  touched={touched}
                  allowPartySearch={allowSearchable}
                  submitCount={submitCount}
                  disableFields={selectedPartyId || isCurrentContactAnAgency}
                />
              </section>

              <section>
                <Heading as="h2" size="a3" spacing="large" className={styles.heading}>
                  Entity Address
                </Heading>
                <AddressFormGroup
                  values={values?.address}
                  touched={touched}
                  errors={errors}
                  submitCount={submitCount}
                  disableFields={selectedPartyId || isCurrentContactAnAgency}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </section>

              <section>
                <Heading as="h2" size="a3" spacing="large" className={styles.heading}>
                  Linked Bank Account
                </Heading>
                <BankDetails
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  accountDescription
                  disableFields={selectedPartyId || isCurrentContactAnAgency}
                />
              </section>
            </div>
          </FormLoader>
        )
      }}
    </Formik>
  )
}

PartyEntityForm.propTypes = propTypes

export default PartyEntityForm
