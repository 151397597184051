import React from 'react'
import PropTypes from 'prop-types'
import { customPropTypes } from '../../../../../views/utils'
import { CurrencyField, FundDistribution, Segment, Select, TextField, TextInput } from '../../../../../views/components'
import styles from './PaymentAllocation.module.scss'
import { subscribe } from 'react-contextual'
import AdhocInvoiceProvider from '../../../AdhocInvoiceProvider'
import { useSelector } from 'react-redux'
import { depositInvoiceTypes } from 'modules/api/invoice/constants'
import { map, pathOr, pipe, sum } from 'ramda'
import { $TSFixMe } from 'types/ts-migrate'
import { agencyApiSelectors } from 'modules/api/agency'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'
import { portfolioApiSelectors } from 'modules/api/portfolio'
import { partyApiSelectors } from 'modules/api/party'

const propTypes = {
  invoiceType: PropTypes.string,
  invoiceTypes: PropTypes.array,
  onInvoiceTypeChange: PropTypes.func,
  amount: PropTypes.string.isRequired,
  onAmountChange: PropTypes.func,
  vatApplied: PropTypes.bool,
  onVatChange: PropTypes.func,
  onBeneficiaryVatChange: PropTypes.func,
  onNewBeneficiaryAdded: PropTypes.func,
  onBeneficiaryRemoved: PropTypes.func,
  onNewBeneficiaryAmountChange: PropTypes.func,
  onDepositManagedByChange: PropTypes.func,
  onBeneficiaryReferenceChange: PropTypes.func,
  beneficiaries: PropTypes.arrayOf(
    PropTypes.shape({
      beneficiary: PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.shape({
          name: PropTypes.string,
          // @ts-expect-error ts-migrate(2322) FIXME: Type '(props: any, propName: any, componentName: a... Remove this comment to see the full error message
          partyId: customPropTypes.uuid,
          partyTag: PropTypes.string,
          reference: PropTypes.string,
          amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          vat: PropTypes.bool,
        }),
      }),
    }),
  ),
  onDepositBeneficiaryTransferChange: PropTypes.func,
  propertyOwnerName: PropTypes.string,
  portfolioId: PropTypes.string,
  customerId: PropTypes.string,
  customerTag: PropTypes.string,
}

const defaultProps = {
  invoiceType: '',
  onAmountChange: (e: any) => e,
  onNewBeneficiaryAdded: (beneficiary: any) => beneficiary,
  beneficiaries: [],
}

const BeforeContent = ({
  amount,
  onAmountChange,
  invoiceTypes,
  invoiceType,
  onInvoiceTypeChange,
  errors,
  vatApplied,
  onVatChange,
  description,
  onDescriptionChange,
  portfolioId,
  customerId,
  customerTag,
}: any): React.ReactElement => {
  const _invoiceTypes =
    portfolioId && customerTag === 'Application'
      ? invoiceTypes.filter((type: $TSFixMe) => type.value === 'ApplicationFee' || type.value === 'TenantRefund')
      : portfolioId
      ? invoiceTypes
      : invoiceTypes.filter((type: $TSFixMe) => !depositInvoiceTypes.includes(type.value))

  return (
    <Segment>
      <Segment horizontal>
        <div className={styles['invoice-type']}>
          <Select
            isSearchable
            name="invoiceType"
            value={_invoiceTypes.find((type: any) => type.value === invoiceType?.value)}
            label="Invoice Type"
            options={_invoiceTypes}
            onChange={onInvoiceTypeChange}
            error={errors.invoiceType}
            isDisabled={!customerId}
          />
        </div>
        <div className={styles.amount}>
          <CurrencyField
            includeVatControl
            label={`Amount ${vatApplied ? '(incl. VAT)' : ''}`}
            name="amount"
            placeholder="Enter amount"
            onChange={onAmountChange}
            value={amount}
            vatApplied={vatApplied}
            onToggleChange={onVatChange}
            error={errors.amount}
          />
        </div>
      </Segment>
      <Segment horizontal>
        <TextField
          inputComponent={
            <TextInput
              type={TextFieldTypes.text}
              name="clickupRef"
              value={description}
              onChange={onDescriptionChange}
            />
          }
          label="Description"
        />
      </Segment>
    </Segment>
  )
}

const PaymentAllocation = (props: any): React.ReactElement => {
  const {
    invoiceType,
    invoiceTypes,
    onInvoiceTypeChange,
    amount,
    onAmountChange,
    beneficiaries,
    onNewBeneficiaryAdded,
    onBeneficiaryRemoved,
    onNewBeneficiaryAmountChange,
    onBeneficiariesOrderChange,
    partyTags,
    errors,
    vatApplied,
    onVatChange,
    onBeneficiaryVatChange,
    easyPayData,
    fetchEasyPayData,
    onDepositBeneficiaryTransferChange,
    description,
    onDescriptionChange,
    onDepositManagedByChange,
    onBeneficiaryReferenceChange,
    portfolioId,
    customerId,
    customerTag,
  } = props

  const agencyName = useSelector(agencyApiSelectors.getCurrentAgencyName)
  const agencyBankDetails = useSelector(agencyApiSelectors.getCurrentAgencyBankDetails)
  const propertyOwnerId = useSelector((state: any) =>
    portfolioApiSelectors.getLandlordByPortfolioId(state)(portfolioId),
  )
  const propertyOwnerName = useSelector((state: any) => partyApiSelectors.getPartyNameById(state)(propertyOwnerId))
  const propertyOwnerBankDetails = useSelector((state: any) =>
    partyApiSelectors.getPartyBankDetails(state)(propertyOwnerId),
  )

  const getPaymentRuleAmountsSum = (): $TSFixMe =>
    pipe(map(pathOr(0, ['beneficiary', 'value', 'amount'])), sum)(beneficiaries)

  return (
    <div className={styles.root}>
      <FundDistribution
        headerText="Payment allocation"
        beforeContent={
          <BeforeContent
            amount={amount}
            invoiceType={invoiceType}
            invoiceTypes={invoiceTypes}
            onAmountChange={onAmountChange}
            onInvoiceTypeChange={onInvoiceTypeChange}
            errors={errors}
            onVatChange={onVatChange}
            vatApplied={vatApplied}
            description={description}
            onDescriptionChange={onDescriptionChange}
            portfolioId={portfolioId}
            customerId={customerId}
            customerTag={customerTag}
          />
        }
        balance={parseFloat(amount)}
        onNewBeneficiaryAdded={onNewBeneficiaryAdded}
        onBeneficiaryRemoved={onBeneficiaryRemoved}
        onNewBeneficiaryAmountChange={onNewBeneficiaryAmountChange}
        onBeneficiariesOrderChange={onBeneficiariesOrderChange}
        onDepositManagedByChange={onDepositManagedByChange}
        onBeneficiaryReferenceChange={onBeneficiaryReferenceChange}
        beneficiaries={beneficiaries}
        partySearchTags=""
        partyTags={partyTags}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'object | ... Remove this comment to see the full error message
        errors={JSON.stringify(errors)}
        easyPayData={easyPayData}
        onVatChange={onBeneficiaryVatChange}
        fetchEasyPayData={fetchEasyPayData}
        invoiceType={invoiceType}
        agencyName={agencyName}
        agencyBankDetails={agencyBankDetails}
        onDepositBeneficiaryTransferChange={onDepositBeneficiaryTransferChange}
        propertyOwnerName={propertyOwnerName}
        propertyOwnerId={propertyOwnerId}
        propertyOwnerBankDetails={propertyOwnerBankDetails}
        unallocatedAmount={parseFloat(amount) - getPaymentRuleAmountsSum() || 0}
        portfolioId={portfolioId}
      />
    </div>
  )
}

PaymentAllocation.propTypes = propTypes
PaymentAllocation.defaultProps = defaultProps

export default subscribe([AdhocInvoiceProvider], ({ fetchEasyPayData, easyPayData }: any) => ({
  fetchEasyPayData,
  easyPayData,
}))(PaymentAllocation)
