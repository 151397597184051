import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { isEmpty } from 'ramda'
import * as yup from 'yup'
import { Heading, Tabs } from '../../..'
import PartyNaturalPersonForm from './PartyNaturalPersonForm'
import PartyEntityForm from './PartyEntityForm'
import styles from './PartyForm.module.scss'
import { $TSFixMe } from 'types/ts-migrate'
import { useSelector } from 'react-redux'
import { userApiSelectors } from 'modules/api/user'
import { identityNumberValidation, taxNumberValidation, vatNumberValidation } from 'utils/yup'
import { agencyApiSelectors } from 'modules/api/agency'

const propTypes = {
  hideTabs: PropTypes.bool,
  contactInitialValues: PropTypes.object,
  partyType: PropTypes.oneOf(['person', 'company']),
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isLoading: PropTypes.bool,
  getGeneralErrors: PropTypes.func,
  getFieldErrors: PropTypes.func,
  transformValues: PropTypes.func,
  allowSearchable: PropTypes.bool,
  getButtonText: PropTypes.func,
  isDisabled: PropTypes.bool,
  isEditingContact: PropTypes.bool,
}

const genericNaturalPersonFields = {
  firstName: yup
    .string()
    .required('required.')
    .matches(/^[^0-9]*$/, {
      message: 'must be alphabetic.',
    }),
  lastName: yup
    .string()
    .required('required.')
    .matches(/^[^0-9]*$/, {
      message: 'must be alphabetic.',
    }),
  emailAddress: yup.string().required('required.').email('Email is invalid.'),
  dateOfBirth: yup.string(),
  accountNumber: yup.string().matches(/^[0-9]*$/, {
    message: 'must be a number.',
    excludeEmptyString: true,
  }),
}
const genericEntityFields = {
  companyName: yup.string().trim().required('required.'),
  companyRegistration: yup
    .string()
    .trim()
    .nullable()
    .test('companyRegistration', function (val) {
      if (val?.substring(0, 2) === 'IT' || val?.substring(0, 2) === 'MT') {
        if (val.match(/^(IT|MT)(\d){1,6}\/(\d){1,4}([(][A-Z]{1,3}[)])?$/)) {
          return true
        } else {
          return this.createError({
            path: 'companyRegistration',
            message: 'Must be in the format: IT******/****(*) or MT******/****(*)',
          })
        }
      } else if (val?.substring(0, 2) === 'SS') {
        if (val.match(/^(SS)(\d+)\/(\d){4}$/)) {
          return true
        } else {
          return this.createError({
            path: 'companyRegistration',
            message: 'Must be in the format: SS*/****',
          })
        }
      } else if (val) {
        if (val.match(/([0-9]{4}\/[0-9]{6}\/[0-9]{2})/)) {
          return true
        } else {
          return this.createError({
            path: 'companyRegistration',
            message: 'Must be in the format: ****/******/**',
          })
        }
      }
      return true
    }),
  emailAddress: yup.string().trim().required('required.').email('Email is invalid.'),
  contactPersonFirstName: yup.string().trim(),
  contactPersonLastName: yup.string().trim(),
  address: yup.object().shape({
    addressLine1: yup.string().when('addressLine2', {
      is: addressLine2 => addressLine2 && addressLine2.length > 0,
      then: yup.string().required('required.'),
    }),
    addressLine2: yup.string(),
    city: yup.string(),
    province: yup.string(),
    postalCode: yup.string(),
    country: yup.string(),
  }),
  accountNumber: yup.string().matches(/^[0-9]*$/, {
    message: 'must be a number.',
    excludeEmptyString: true,
  }),
}

const naturalPersonValidationSchemaBase = yup.object().shape({
  ...genericNaturalPersonFields,
})

const naturalPersonValidationSchemaWithSaIdNumber = naturalPersonValidationSchemaBase.shape({
  idNumber: identityNumberValidation,
  taxNumber: taxNumberValidation('taxNumber'),
})

const naturalPersonValidationSchemaWithPassportNumber = naturalPersonValidationSchemaBase.shape({
  passport: yup.string().required('required.'),
  taxNumber: taxNumberValidation('taxNumber'),
})

const entityValidationSchema = yup.object().shape({
  ...genericEntityFields,
  vatNumber: vatNumberValidation('vatNumber'),
})

const entityValidationSchemaSupportUser = yup.object().shape({
  ...genericEntityFields,
})

const PartyForm = ({
  handleSubmit,
  contactInitialValues,
  partyType,
  isSubmitting,
  getGeneralErrors,
  getFieldErrors,
  transformValues,
  allowSearchable,
  getButtonText,
  isDisabled,
  isEditingContact,
}: $TSFixMe) => {
  const [partyTypeIndex, setPartyTypeIndex] = useState(0)
  const getCurrentIdentificationType = isEmpty(contactInitialValues.passport) ? 'saIdNumber' : 'passportNumber'
  const [stateIdentificationType, setStateIdentificationType] = useState(getCurrentIdentificationType)
  const isTestAgency = useSelector(agencyApiSelectors.getCurrentAgencyIsTestAgency)

  useEffect(() => {
    const index = partyType === 'person' ? 0 : 1
    setPartyTypeIndex(index)
  }, [partyType])

  const onTabChange = (pti: any) => setPartyTypeIndex(pti)

  const getValidationSchema = () => {
    return partyTypeIndex === 0
      ? stateIdentificationType === 'saIdNumber' && !isTestAgency
        ? naturalPersonValidationSchemaWithSaIdNumber
        : stateIdentificationType === 'saIdNumber' && isTestAgency
        ? naturalPersonValidationSchemaBase
        : stateIdentificationType === 'passportNumber' && !isTestAgency
        ? naturalPersonValidationSchemaWithPassportNumber
        : naturalPersonValidationSchemaBase
      : !isTestAgency
      ? entityValidationSchema
      : entityValidationSchemaSupportUser
  }

  console.log('getValidationSchema: ', getValidationSchema())

  const onSubmit = useCallback(
    formValues => {
      handleSubmit(transformValues(partyTypeIndex, formValues))
    },
    [partyTypeIndex, handleSubmit, transformValues],
  )

  const handleStateIdentificationType = (identificationType: string) => {
    setStateIdentificationType(identificationType)
  }

  const tabs = [
    {
      title: 'Natural Person',
      content: (
        <PartyNaturalPersonForm
          onSubmit={onSubmit}
          initialValues={contactInitialValues}
          validationSchema={getValidationSchema()}
          isSubmitting={isSubmitting}
          getGeneralErrors={payload => getGeneralErrors(transformValues(partyTypeIndex, payload))}
          getFieldErrors={payload => getFieldErrors(transformValues(partyTypeIndex, payload))}
          allowSearchable={allowSearchable}
          getButtonText={getButtonText}
          isDisabled={isDisabled}
          handleStateIdentificationType={handleStateIdentificationType}
          stateIdentificationType={stateIdentificationType}
        />
      ),
    },
    {
      title: 'Entity',
      content: (
        <PartyEntityForm
          onSubmit={onSubmit}
          initialValues={contactInitialValues}
          validationSchema={getValidationSchema()}
          isSubmitting={isSubmitting}
          getGeneralErrors={payload => getGeneralErrors(transformValues(partyTypeIndex, payload))}
          getFieldErrors={payload => getFieldErrors(transformValues(partyTypeIndex, payload))}
          allowSearchable={allowSearchable}
          getButtonText={getButtonText}
          isDisabled={isDisabled}
        />
      ),
    },
  ]

  const classes = cx(styles.root, { [styles['edit-form']]: isEditingContact })

  return (
    <div className={classes}>
      <Heading as="h2" size="a3" spacing="large" className={styles.heading}>
        Contact Details
      </Heading>
      <Tabs tabs={tabs} selectedTab={partyTypeIndex} onTabChange={onTabChange} />
    </div>
  )
}

PartyForm.propTypes = propTypes

export default PartyForm
