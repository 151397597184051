import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { Formik } from 'formik'
import { pathOr } from 'ramda'
import { chatbotEvents, chatbotSelectors } from '../../../../ui/ChatBot'
import { CurrencyField, FormField, Divider, Button, Checkbox } from '../../../../../views/components'
import styles from './UnmanagedRentAndFeesForm.module.scss'
import { portfolioApiSelectors } from '../../../../api/portfolio'
import { $TSFixMe } from 'types/ts-migrate'
import { getCurrentPortfolioId } from 'modules/ui/ChatBot/selectors'

const validationSchema = yup.object().shape({
  monthlyRentAmount: yup.string().required('required.'),
})

const UnmanagedRentAndFeesForm = () => {
  const dispatch = useDispatch()
  const handleSubmit = useCallback(
    values => {
      dispatch(chatbotEvents.rentValuesSubmitted(values))
    },
    [dispatch],
  )

  const currentPortfolio = useSelector(getCurrentPortfolioId)
  const isActivePortfolio = useSelector(portfolioApiSelectors.isActivePortfolio)(currentPortfolio)
  const initialValues = useSelector(chatbotSelectors.getLeaseRentAndFeesDialog)

  return (
    <Formik onSubmit={handleSubmit} validationSchema={validationSchema} initialValues={initialValues}>
      {({ values, errors, touched, setFieldValue, handleBlur, handleSubmit }) => {
        const getFieldErrors = (name: string) => pathOr('', [name], errors)

        return (
          <div className={styles.root}>
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={styles.body}>
                <div className="rbn--row">
                  <div className="rbn--col-md-4">
                    <FormField>
                      <CurrencyField
                        name="monthlyRentAmount"
                        label="Rental amount"
                        value={values.monthlyRentAmount}
                        error={getFieldErrors('monthlyRentAmount')}
                        onChange={(e: $TSFixMe) => setFieldValue('monthlyRentAmount', e.target.value)}
                        onBlur={handleBlur}
                      />
                    </FormField>
                  </div>
                  <div className="rbn--col-md-4">
                    <FormField>
                      <CurrencyField
                        name="damageDepositAmount"
                        label="Damage deposit"
                        error={getFieldErrors('damageDepositAmount')}
                        value={values.damageDepositAmount}
                        onChange={(e: $TSFixMe) => setFieldValue('damageDepositAmount', e.target.value)}
                        onBlur={handleBlur}
                      />
                    </FormField>
                  </div>
                </div>
                <div className="rbn--row">
                  <div className="rbn--col-md-4">
                    <FormField>
                      <CurrencyField
                        name="serviceDepositAmount"
                        label="Service deposit"
                        error={getFieldErrors('serviceDepositAmount')}
                        value={values.serviceDepositAmount}
                        onChange={(e: $TSFixMe) => setFieldValue('serviceDepositAmount', e.target.value)}
                        onBlur={handleBlur}
                      />
                    </FormField>
                    <div className="rbn--col-md-4">
                      <FormField>
                        <CurrencyField
                          name="keyDepositAmount"
                          label="Key deposit"
                          error={getFieldErrors('keyDepositAmount')}
                          value={values.keyDepositAmount}
                          onChange={(e: any) => setFieldValue('keyDepositAmount', e.target.value)}
                          onBlur={handleBlur}
                        />
                      </FormField>
                    </div>
                  </div>
                </div>

                <Divider>
                  <h3>Additional fees (Optional)</h3>
                </Divider>

                <div className="rbn--row">
                  <div className="rbn--col-md-4">
                    <FormField>
                      <CurrencyField
                        includeVatControl
                        vatApplied={values.applicationFee.vatable}
                        name="applicationFee.netAmount"
                        label={`Application fee ${values.applicationFee.vatable ? '(incl. VAT)' : ''}`}
                        error={getFieldErrors('applicationFee.netAmount')}
                        value={values.applicationFee.netAmount}
                        onChange={(e: $TSFixMe) => setFieldValue('applicationFee.netAmount', e.target.value)}
                        onToggleChange={(vatApplied: $TSFixMe) => {
                          setFieldValue('applicationFee.vatable', vatApplied)
                        }}
                        onBlur={handleBlur}
                      />
                    </FormField>
                  </div>
                  <div className="rbn--col-md-4">
                    <FormField>
                      <CurrencyField
                        includeVatControl
                        vatApplied={values.leaseFee.vatable}
                        name="leaseFee.netAmount"
                        label={`Lease fee ${values.leaseFee.vatable ? '(incl. VAT)' : ''}`}
                        value={values.leaseFee.netAmount}
                        error={getFieldErrors('leaseFee.netAmount')}
                        onChange={(e: $TSFixMe) => setFieldValue('leaseFee.netAmount', e.target.value)}
                        onToggleChange={(vatApplied: $TSFixMe) => {
                          setFieldValue('leaseFee.vatable', vatApplied)
                        }}
                        onBlur={handleBlur}
                      />
                    </FormField>
                  </div>
                </div>
                <div className="rbn--row">
                  <div className="rbn--col-md-4">
                    <FormField>
                      <CurrencyField
                        name="firstMonthRentAmount"
                        label="Pro-rata rental amount"
                        placeholder="0 000.00"
                        value={values.firstMonthRentAmount}
                        error={getFieldErrors('firstMonthRentAmount')}
                        onChange={(e: $TSFixMe) => setFieldValue('firstMonthRentAmount', e.target.value)}
                        onBlur={handleBlur}
                      />
                    </FormField>
                  </div>
                </div>
              </div>
              <div className={styles.footer}>
                <Button type="submit">{isActivePortfolio ? 'Save' : "Done, let's continue"}</Button>
              </div>
            </form>
          </div>
        )
      }}
    </Formik>
  )
}

export default UnmanagedRentAndFeesForm
