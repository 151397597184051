import React, { useEffect, useMemo, useState } from 'react'
import {
  flatten,
  head,
  isEmpty,
  length,
  map,
  not,
  path,
  pathOr,
  pick,
  pipe,
  sort,
  tail,
  uniq,
  values,
  when,
} from 'ramda'
import { subscribe } from 'react-contextual'
import { stringUtils } from '../../../../utils'
import { SearchFilterProvider } from '../../../../views/providers'
import { AggregateTable, Button, Header, InitialsBadge } from '../../../../views/components'
import PeopleIcon from '../../../../views/components/atoms/Svgs/People'
import { ContactsProvider } from '../..'
import Name from './Name'
import styles from './ContactsTable.module.scss'
import { sortByProps } from 'utils/array'
import { $TSFixMe } from 'types/ts-migrate'
import { get } from 'lodash-es'

const ContactsTable = ({
  filters,
  openContact,
  primaryPanel,
  searchQuery,
  activeFilterIndex,
  currentParty,
}: any): React.ReactElement => {
  const [data, setData] = useState([])
  const filter: $TSFixMe = pathOr({}, [activeFilterIndex], filters)
  useEffect(() => {
    setData(
      filter.contacts.map((contact: $TSFixMe) => ({
        ...contact,
        meta: {
          name: contact?.name,
          contactNumber: contact?.contactNumber,
          email: contact?.email,
        },
      })),
    )
  }, [filter.contacts])

  const filteredContacts = useMemo(() => {
    return data.filter(inv => stringUtils.jsonStringSearch(searchQuery, inv))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])

  const badgeColumn = {
    Header: '',
    accessor: 'badge',
    style: {
      paddingLeft: '16px',
      minWidth: '60px',
      maxWidth: '80px',
    },
  }

  const nameColumn = {
    Header: 'Name',
    accessor: 'name',
    style: {
      minWidth: '400px',
      textAlign: 'left',
      justifyContent: 'flex-start',
      paddingLeft: '16px',
    },
    sort: {
      enabled: true,
      order: false,
    },
  }

  const accountsColumn = {
    Header: '',
    accessor: 'accounts',
    style: {
      justifyContent: 'flex-end',
    },
  }

  const contactsColumn = {
    Header: 'Contact Number',
    accessor: 'contactNumber',
    style: {
      maxWidth: '150px',
    },
    sort: {
      enabled: true,
      order: false,
    },
  }

  const emailColumn = {
    Header: 'Email',
    accessor: 'email',
    style: {
      maxWidth: '250px',
    },
    sort: {
      enabled: true,
      order: false,
    },
  }

  const columns = path(['open'], primaryPanel)
    ? [badgeColumn, nameColumn]
    : [badgeColumn, nameColumn, contactsColumn, emailColumn, accountsColumn]

  const currentPartyId = get(currentParty, 'id')

  const contactsData = useMemo(() => {
    const getAccountTags = pipe(map(pipe(pick(['tag']), values)), flatten, uniq)
    const getRemainingAccountsCount = pipe(tail, length)
    const getNameMeta = (accounts: any): $TSFixMe => {
      const applicationAccounts = accounts.filter((account: $TSFixMe) => account.tag === 'Application')
      const nonApplicationAccounts = accounts.filter((account: $TSFixMe) => account.tag !== 'Application')
      const shuffledAccounts = [...nonApplicationAccounts, ...applicationAccounts]
      return pipe(
        when(
          (shuffledAccounts: any) => not(isEmpty(shuffledAccounts)),
          pipe(head, (acc: any) => {
            const remainingAccounts =
              getRemainingAccountsCount(shuffledAccounts) > 0
                ? `and ${getRemainingAccountsCount(shuffledAccounts)} others`
                : ''
            return acc.propertyAddress
              ? `${acc.tag as string}: ${acc.propertyAddress as string} ${remainingAccounts}`
              : null
          }),
        ),
      )(shuffledAccounts)
    }

    const buildContactObj = (contact: any): $TSFixMe => {
      return {
        id: contact.id,
        highlighted: currentPartyId === contact.id,
        meta: contact.meta,
        badge: <InitialsBadge name={contact.name} />,
        partyType: contact.partyType,
        name: <Name name={contact.name} meta={getNameMeta(contact.accounts)} />,
        lastName: contact.lastName ? contact.lastName : contact.name,
        contactNumber: contact.contactNumber,
        email: contact.email,
        accounts: (
          <div className={styles.tags}>
            {pipe(
              map((tag: any) => {
                if (tag === 'Agent') {
                  return 'Team'
                } else if (tag.toLowerCase().includes('contractor')) {
                  return filter.key === 'suppliers' ? tag.substring(10, tag.length) : 'Contractor'
                } else if (tag.toLowerCase().includes('supplier')) {
                  return filter.key === 'suppliers' ? tag.substring(8, tag.length) : 'Supplier'
                } else {
                  return tag
                }
              }),
              uniq,
              sort((a: any, b: any) => a.localeCompare(b)),
              map((tag: any) => {
                const modifierProp = (): $TSFixMe => {
                  switch (tag) {
                    case 'Tenant':
                      return { tertiary: true }
                    case 'Owner':
                      return { secondary: true }
                    case 'Team':
                    default:
                      return { primary: true }
                  }
                }

                return (
                  <Button {...modifierProp()} pill size="sm">
                    {tag}
                  </Button>
                )
              }),
            )([...getAccountTags(contact.accounts), ...contact.tags])}
          </div>
        ),
      }
    }

    const contactsResult = searchQuery ? map(buildContactObj, filteredContacts) : map(buildContactObj, data)

    return sortByProps(['lastName', 'name'], contactsResult)
  }, [data, filteredContacts, searchQuery, filter])

  return useMemo(
    () => (
      <div className={styles.root}>
        <Header icon={<PeopleIcon />} text="Contacts" />

        <AggregateTable
          rowHeight={80}
          columns={columns}
          data={contactsData}
          onRowClick={data => openContact(data.id)}
        />
      </div>
    ),
    [columns, contactsData, openContact],
  )
}

export default subscribe(
  [ContactsProvider, SearchFilterProvider],
  ({ filters, openContact, primaryPanel, currentParty }: any, { searchQuery, activeFilterIndex }: any) => ({
    filters,
    openContact,
    primaryPanel,
    currentParty,
    searchQuery,
    activeFilterIndex,
  }),
)(ContactsTable)
