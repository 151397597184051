import React, { FC } from 'react'
import { TextField, FormField, TextInput } from '../..'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'
import { formUtils } from 'utils/index'

type Props = {
  values?: {
    addressLine1: string
    addressLine2: string
    city: string
    province: string
    postalCode: string
    country: string
  }
  errors?: any
  touched?: any
  submitCount?: number
  disableFields?: boolean
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void
}

const emptyValues = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  province: '',
  postalCode: '',
  country: '',
}

const AddressFormGroup: FC<Props> = ({
  values = emptyValues,
  errors,
  touched,
  submitCount,
  disableFields,
  handleChange,
  handleBlur,
}) => {
  const getFieldError = formUtils.getFieldError(submitCount, touched, errors)

  return (
    <>
      <div className="rbn--row">
        <div className="rbn--col-md-8">
          <FormField>
            <TextField
              inputComponent={
                <TextInput
                  type={TextFieldTypes.text}
                  name="address.addressLine1"
                  placeholder="Address line 1"
                  value={values?.addressLine1}
                  disabled={disableFields}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              label="Address"
              error={getFieldError('address.addressLine1')}
            />
          </FormField>
        </div>
      </div>
      <div className="rbn--row">
        <div className="rbn--col-md-8">
          <FormField>
            <TextField
              inputComponent={
                <TextInput
                  type={TextFieldTypes.text}
                  name="address.addressLine2"
                  placeholder="Address line 2"
                  value={values?.addressLine2}
                  disabled={disableFields}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              error={getFieldError('address.addressLine2')}
            />
          </FormField>
        </div>
      </div>
      <div className="rbn--row">
        <div className="rbn--col-md-3">
          <FormField>
            <TextField
              inputComponent={
                <TextInput
                  type={TextFieldTypes.text}
                  name="address.city"
                  placeholder="e.g. Cape Town"
                  value={values.city}
                  disabled={disableFields}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              label="City / Municipality"
              error={getFieldError('address.city')}
            />
          </FormField>
        </div>
        <div className="rbn--col-md-3">
          <FormField>
            <TextField
              inputComponent={
                <TextInput
                  type={TextFieldTypes.text}
                  name="address.province"
                  placeholder="e.g. Western Cape"
                  value={values.province}
                  disabled={disableFields}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              label="Province"
              error={getFieldError('address.province')}
            />
          </FormField>
        </div>
        <div className="rbn--col-md-2">
          <FormField>
            <TextField
              inputComponent={
                <TextInput
                  type={TextFieldTypes.text}
                  name="address.postalCode"
                  placeholder="e.g. 8000"
                  value={values.postalCode}
                  disabled={disableFields}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              label="Postal Code"
            />
          </FormField>
        </div>
        <div className="rbn--col-md-3">
          <FormField>
            <TextField
              inputComponent={
                <TextInput
                  type={TextFieldTypes.text}
                  name="address.country"
                  placeholder="e.g. South Africa"
                  value={values.country}
                  disabled={disableFields}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              label="Country"
              error={getFieldError('address.country')}
            />
          </FormField>
        </div>
      </div>
    </>
  )
}

export default AddressFormGroup
