import { connect } from 'react-redux'
import { invoiceApiEvents, invoiceApiSelectors } from '../../../modules/api/invoice'
import { chatbotEvents, chatbotSelectors } from '../../../modules/ui/ChatBot'
import { uiInvoiceSelectors } from '../../../modules/ui/invoices'
import { agencyApiSelectors } from '../../../modules/api/agency'
import { userApiSelectors } from '../../../modules/api/user'
import { partyApiEvents, partyApiSelectors } from '../../../modules/api/party'
import { getInvoicesInitialValues } from 'modules/ui/ChatBot/Dialogs/LeaseInvoices'
import { InvoicesForm } from '../../components'
import { portfolioApiEvents, portfolioApiSelectors } from '../../../modules/api/portfolio'
import { reconApiEvents, reconApiSelectors } from '../../../modules/api/recon'
import {
  getAllOwnerPartyIdsByPortfolioId,
  getPrimaryLandlordByPortfolioId,
  getPrimaryTenantByPortfolioId,
} from '../../../modules/api/portfolio/selectors'
import { uiEvents } from '../../../modules/ui'
import { getCurrentLeaseType } from 'modules/ui/ChatBot/Dialogs/LeaseTypeSelection'
import { $TSFixMe } from 'types/ts-migrate'
import { getSelectedOwner } from 'modules/ui/ChatBot/Dialogs/OwnerDetails'

const mapStateToProps = (state: any): $TSFixMe => {
  const monthlyInvoiceTypes = uiInvoiceSelectors
    .getNonAdhocInvoiceTypesForSelect(state)
    .filter(
      (p: any) => !['RentInvoice', 'ServiceDeposit', 'DamageDeposit', 'DepositTopUp', 'KeyDeposit'].includes(p.value),
    )
  const onceOffInvoiceTypes = uiInvoiceSelectors
    .getNonAdhocInvoiceTypesForSelect(state)
    .filter((p: any) => !['RentInvoice'].includes(p.value))

  const agencyId = userApiSelectors.getCurrentAgencyId(state)
  const currentPortfolioId = chatbotSelectors.getCurrentPortfolioId(state)
  const primaryOwnerId: string = getPrimaryLandlordByPortfolioId(state)(currentPortfolioId) ?? ''
  const primaryTenantPartyId: string = getPrimaryTenantByPortfolioId(state)(currentPortfolioId) ?? ''
  const allOwners = getAllOwnerPartyIdsByPortfolioId(state)(currentPortfolioId)

  const getInvoiceResult = (id: string, tag: string): $TSFixMe => ({
    label: `${tag}: ${partyApiSelectors.getPartyNameById(state)(id) as string}`,
    value: id,
    tag,
  })

  const invoiceParties = [getInvoiceResult(primaryTenantPartyId, 'Tenant'), getInvoiceResult(primaryOwnerId, 'Owner')]

  const getDefaultBeneficiaries = (): $TSFixMe => {
    const allOwnerResults = allOwners.map((owner: $TSFixMe) =>
      partyApiSelectors.getPartyForSearchResult(state)(owner.partyId),
    )
    const agencyResult = agencyApiSelectors.getAgencyForSearchResult(state)(agencyId)
    const resultSets = [[]]
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ id?: undefined; text?: undefin... Remove this comment to see the full error message
    allOwnerResults && resultSets[0].push(...allOwnerResults)
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ id: any; text: any; partyTag: ... Remove this comment to see the full error message
    agencyResult && resultSets[0].push(agencyResult)
    return resultSets
  }

  const defaultBeneficiaries = getDefaultBeneficiaries()

  /**
   * @todo create selector for agents
   * is this necessary!?
   */
  const emptyInvoice = {
    editing: true,
    autoDeliver: false /** @todo invoice-automation: change to true when re-implemented */,
    category: '',
    invoiceParty: {},
    interval: 'BeginningOfMonth',
    netAmount: '',
    vatable: false,
    paymentRules: [],
  }

  const propertyOwnerName = primaryOwnerId ? partyApiSelectors.getPartyNameById(state)(primaryOwnerId) : null
  const propertyOwnerBankDetails = primaryOwnerId ? partyApiSelectors.getPartyBankDetails(state)(primaryOwnerId) : null
  const ownerId = getSelectedOwner(state)
  const ownerName = partyApiSelectors.getPartyNameById(state)(ownerId)

  return {
    agencyId,
    ownerId,
    ownerName,
    currentPortfolioId,
    monthlyInvoiceTypes,
    onceOffInvoiceTypes,
    invoiceParties,
    emptyInvoice,
    defaultBeneficiaries,
    propertyOwnerName,
    propertyOwnerBankDetails,
    getPartyBankDetails: (partyId: string) => partyApiSelectors.getPartyBankDetails(state)(partyId),
    leaseType: getCurrentLeaseType(state),
    getInvoiceTypeNameByValue: (value: any) => invoiceApiSelectors.getInvoiceTypeNameByValue(state)(value),
    initialValues: getInvoicesInitialValues(state),
    getPartyTagsById: (id: any) => partyApiSelectors.getPartyTagsById(state)(id),
    partyTags: partyApiSelectors.getPartyTags(state),
    easyPayData: reconApiSelectors.getEasyPayData(state),
    agencyName: agencyApiSelectors.getCurrentAgencyName(state),
    agencyBankDetails: agencyApiSelectors.getCurrentAgencyBankDetails(state),
    isActivePortfolio: portfolioApiSelectors.isActivePortfolio(state)(currentPortfolioId),
    isDraftPortfolio: portfolioApiSelectors.isDraftPortfolio(state)(currentPortfolioId),
    selectedTenant: primaryTenantPartyId,
    isReadOnly: userApiSelectors.isReadOnlyRole(state),
    getPartyAccount: (partyId: string, portfolioId: string, preferredTag: string) =>
      partyApiSelectors.getPartyAccountByPortfolioId(state)(partyId, portfolioId, preferredTag),
    getPartyNameById: (id: any) => partyApiSelectors.getPartyNameById(state)(id),
    getInvoiceTypeByValue: (value: any) => invoiceApiSelectors.getInvoiceTypeByValue(state)(value),
    isCurrentAgencyGlobalVatEnabled: agencyApiSelectors.getCurrentAgencyGlobalVatEnabled(state),
  }
}

const mapDispatchToProps = (dispatch: any): $TSFixMe => ({
  redirect: (path: any) => dispatch(uiEvents.redirect(path)),
  fetchInvoiceTypes: () => dispatch(invoiceApiEvents.invoiceTypes_request()),
  fetchPartyTags: () => dispatch(partyApiEvents.partyTags_request()),
  handleInvoiceTemplateChanged: (id: any) => dispatch(portfolioApiEvents.invoiceTemplateChanged(id)),
  handleInvoiceTemplateRemoved: (id: any) => dispatch(portfolioApiEvents.invoiceTemplateRemoved(id)),
  fetchEasyPayData: (reference: any) => dispatch(reconApiEvents.easypayData_request({ reference })),
  handleSubmit: (values: any) => {
    dispatch(chatbotEvents.invoiceValuesSubmitted(values))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesForm)
