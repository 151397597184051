import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { animationFrameScheduler, interval } from 'rxjs'
import { map, takeWhile } from 'rxjs/operators'
import { get, isEmpty, sum, sumBy } from 'lodash-es'
import { subscribe } from 'react-contextual'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'
import { withFormik } from 'formik'
import * as yup from 'yup'
import { concat, filter, flatten, join, map as rMap, path, pathOr, pipe, prop } from 'ramda'
import { InvoicesProvider } from '../../../../providers'
import { arrayUtils, currencyUtils, dateUtils } from '../../../../../utils'
import { LayoutContext } from 'modules/Layout/LayoutProvider'
import FileTable from '../../../../../modules/documents/FileTable'
import { CreditNoteButton, CreditNoteContainer, CreditNoteModal } from '../../../../../modules/creditNote'
import { Button, CurrencyField, DimmerLoader, InvoiceCard, Label, LinearProgress, Segment, TextInput } from '../../..'
import PaymentAllocation from './PaymentAllocation/PaymentAllocation'
import NudgeButton from './NudgeButton'
import Accounting from '../../../atoms/Svgs/Accounting'
import styles from './ViewActiveInvoice.module.scss'
import { hashCode } from 'utils/string'
import TextField from '../../../atoms/TextField/TextField'
import EditBeneficiaryAmount from './EditBeneficiaryAmount/EditBeneficiaryAmount'
import FundDistributionFooter from '../../../organisms/FormFragments/FundDistribution/FundDistributionFooter/FundDistributionFooter'
import { $TSFixMe } from 'types/ts-migrate'
import DownloadInvoicePdfButton from 'modules/invoices/DownloadInvoicePdfButton/DownloadInvoicePdfButton'
import CreditNotesList from 'modules/invoices/CreditNotesList/CreditNotesList'
import {
  transformDepositBeneficiaryForManagedByChange,
  transformDepositBeneficiaryForTransferChange,
} from 'views/components/organisms/FormFragments/FundDistribution/DepositBeneficiary/depositBeneficiaryUtils'
import VATCalculator from 'views/components/molecules/VATControl/VATCalculator'
import { existingInvoiceTypes } from 'utils/invoiceTypes'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'

const propTypes = {
  invoice: PropTypes.object,
  canInvoiceBeNudged: PropTypes.func,
  fetchEasyPayData: PropTypes.func,
  easyPayData: PropTypes.object,
  viewingInvoice: PropTypes.string,
}

const validationSchema = yup.object().shape({
  beneficiaries: yup
    .array(
      yup.object().shape({
        beneficiary: yup.object().shape({
          type: yup.string(),
          value: yup.object().shape({
            amountToPay: yup.mixed().test('amountToPay', function (val) {
              const value = val || 0
              if (value > this.parent.balance) {
                return this.createError({
                  path: this.path,
                  message: `Amount can't exceed Payment rule balance of ${currencyUtils.formatCurrency(
                    this.parent.balance,
                  )}`,
                })
              }
              return true
            }),
            balance: yup.number(),
            /** @todo remove globally */
            // beneficiaryCategory: yup.string(),
            id: yup.string(),
            partyId: yup.string(),
            partyTag: yup.string(),
            reference: yup.string(),
            vat: yup.boolean(),
          }),
        }),
      }),
    )
    .test('beneficiaries', function (beneficiaries) {
      const beneficiariesTotalAmount = sumBy(
        beneficiaries,
        (b: any) => parseFloat(b.beneficiary.value.amountToPay) || 0,
      )
      if (this.parent.customerAvailableFunds && beneficiariesTotalAmount > this.parent.customerAvailableFunds) {
        return this.createError({
          path: 'general',
          message: `Total payment amounts can't exceed available funds of ${
            currencyUtils.formatCurrency(this.parent.customerAvailableFunds) as string
          }`,
        })
      }
      if (beneficiariesTotalAmount > this.parent.balance) {
        return this.createError({
          path: 'general',
          message: `Total payment amounts can't exceed invoice balance of ${
            currencyUtils.formatCurrency(this.parent.balance) as string
          }`,
        })
      }
      return true
    }),
})

class ViewActiveInvoice extends Component<$TSFixMe, $TSFixMe> {
  state = {
    submissionProgress: 0,
    dirty: false,
    isNudgeTooltipOpen: false,
    beneficiariesHash: null,
    fetchingEasyPayData: false,
    currentBeneficiaryIndex: 0,
    currentBeneficiaryReference: null,
    currentBeneficiaryType: null,
    currentViewingInvoice: null,
  }

  cancelPaymentApproval = false

  componentDidMount(): void {
    this.setState({
      beneficiariesHash: hashCode(JSON.stringify(get(this.props, 'currentInvoice.beneficiaries', ''))),
    })
  }

  componentDidUpdate(): void {
    const { easyPayData, viewingInvoice } = this.props
    // @ts-expect-error
    const easyPayReferenceData = pathOr(null, [this.state.currentBeneficiaryReference], easyPayData)

    if (viewingInvoice !== this.state.currentViewingInvoice) {
      this.setState({
        currentViewingInvoice: viewingInvoice,
        currentBeneficiaryIndex: null,
        currentBeneficiaryReference: null,
        currentBeneficiaryType: null,
      })
    }

    if (easyPayReferenceData && this.state.fetchingEasyPayData) {
      const isEasyPayReferenceDataValid = pathOr(false, ['request', 'success'], easyPayReferenceData)
      this.setState({ fetchingEasyPayData: false })

      if (isEasyPayReferenceDataValid) {
        this.handleBeneficiaryReferenceChange(
          this.state.currentBeneficiaryIndex,
          this.state.currentBeneficiaryReference,
          this.state.currentBeneficiaryType,
        )
      }
    }
  }

  handleClose = (): void => {
    const { closeInvoice, values } = this.props
    closeInvoice(values.id, 'active')
  }

  openSnapshot = (): void => {
    this.props.secondaryPanel.open()
  }

  scrollToCreditNotes = (): void => {
    const element = document.getElementById('creditNotes')
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  handleSave = (newBeneficiaries: any): void => {
    const { values, updateBeneficiaries } = this.props
    updateBeneficiaries(
      values.id,
      newBeneficiaries.map((b: any) => ({
        ...b,

        beneficiary: {
          ...b.beneficiary,
          value: {
            ...b.beneficiary.value,
            amount: b.beneficiary.value.amount || 0,
          },
        },
      })),
    )
  }

  getBeneficiaries = (): $TSFixMe => get(this.props, 'values.beneficiaries', [])

  sumBeneficiaryAmounts = (): number =>
    sumBy(this.getBeneficiaries(), (b: any) => {
      const sum = parseFloat(get(b, 'beneficiary.value.amount', 0)) || 0
      return sum
    })

  getPaymentAllocationErrors = (): any => {
    const { errors } = this.props

    const beneficiaryErrors = pipe(
      pathOr([], ['beneficiaries']),
      filter((e: any) => e),
      pathOr(null, [0, 'beneficiary', 'value', 'amountToPay']),
    )(errors)

    let consolidatedErrors: $TSFixMe = {}
    if (beneficiaryErrors) {
      consolidatedErrors = {
        ...{
          beneficiaries: beneficiaryErrors,
        },
        general: errors?.general,
      }
    }

    return consolidatedErrors
  }

  getBeneficiariesForPaymentAllocation = (): $TSFixMe => {
    const { values, getPartyNameById, getBankingPartyById, updateBeneficiaries, easyPayData, isReadOnly } = this.props

    const creditNoteBeneficiaries = (values?.creditNotes || [])
      .map((creditNote: $TSFixMe) => creditNote.beneficiaries)
      .flat()

    return this.getBeneficiaries().map((b: any, index: number) => {
      const partyId = pathOr('', ['beneficiary', 'value', 'partyId'], b)
      const preValidationErrors = pathOr([], ['errors'], getBankingPartyById(partyId))

      const beneficiaryEasyPayRef: string = pathOr('', ['beneficiary', 'value', 'easyPayReference'], b)
      const easyPayReferenceData = pathOr('', [beneficiaryEasyPayRef], easyPayData)
      const easyPayError = pathOr('', ['request', 'response_message'], easyPayReferenceData)

      const easyPayErrors =
        easyPayError && easyPayError !== 'Successful' ? [{ key: 'easypay_ref', message: easyPayError }] : []

      const partialBeneficiaryPayment =
        (b.originalAmount === 0 && b.balance === 0) || (b.originalAmount > 0 && b.originalAmount === b.balance)

      const beneficiaryCreditNotes = creditNoteBeneficiaries.filter(
        (creditNoteBeneficiary: $TSFixMe) => b?.beneficiary?.value?.id === creditNoteBeneficiary.beneficiaryId,
      )

      const beneficiaryCreditNotesSum = sumBy(beneficiaryCreditNotes, (creditNote: $TSFixMe) => {
        return creditNote?.amount || 0
      })

      const beneficiaryBalanceWithoutCreditNotes = b.balance + beneficiaryCreditNotesSum

      return {
        ...b,
        beneficiary: {
          ...b.beneficiary,
          value: {
            ...b.beneficiary.value,
            name: getPartyNameById(partyId),
          },
          errors: concat(preValidationErrors, easyPayErrors),
        },
        isRemovable: partialBeneficiaryPayment,
        Meta: () => (
          <>
            <EditBeneficiaryAmount
              amount={b.originalAmount}
              balance={beneficiaryBalanceWithoutCreditNotes}
              onAmountUpdate={(amount: any) => {
                updateBeneficiaries(values.id, this.updateBeneficiaryAmount(values.beneficiaries, amount, index))
              }}
              isDisabled={isReadOnly}
              isEditable={b.beneficiary.type !== 'DepositBeneficiary'}
            />
            <VATCalculator
              vatApplied={b.beneficiary?.value?.vat}
              disabled={b.beneficiary.type === 'DepositBeneficiary'}
              currencySymbol="R"
              amount={b.beneficiary?.value?.amount}
              onChange={(amountInclVat: number) => {
                updateBeneficiaries(values.id, this.updateBeneficiaryAmount(values.beneficiaries, amountInclVat, index))
              }}
            />
          </>
        ),
      }
    })
  }

  updateBeneficiaryAmount = (beneficiaries: any, amount: any, index: number): $TSFixMe =>
    beneficiaries.map((b: any, i: any) => {
      return {
        ...b,
        beneficiary: {
          ...b.beneficiary,
          value: {
            ...b.beneficiary.value,
            amount: i === index ? amount : b.originalAmount,
          },
        },
      }
    })

  handleNewBeneficiary = (b: any, index: number): void => {
    const { setFieldValue, values, updateBeneficiaries, getCurrentAgencyGlobalVatEnabled } = this.props
    const vatApplied =
      existingInvoiceTypes.includes(values.invoiceType) &&
      b.beneficiary.value.partyTag === 'Agency' &&
      getCurrentAgencyGlobalVatEnabled
        ? true
        : false
    const newBeneficiary = {
      ...b,
      beneficiary: {
        ...b.beneficiary,
        value: {
          ...b.beneficiary.value,
          amount: parseFloat(b.beneficiary?.value?.amount) || 0,
          vat: vatApplied,
        },
      },
      balance: b.beneficiary?.value?.amount || 0,
      originalAmount: parseFloat(b.beneficiary?.value?.amount) || 0, // original amount because `amount` is updated and used to make payments
      availableFunds: get(values, 'customerAvailableFunds', 0),
      invoiceBalance: get(values, 'balance', 0),
    }
    const newBeneficiaries = arrayUtils.insert(index, newBeneficiary, this.getBeneficiaries())
    setFieldValue('beneficiaries', newBeneficiaries)
    const updateBeneficiariesPayload = this.updateBeneficiaryAmount(newBeneficiaries, b.beneficiary.value.amount, index)
    updateBeneficiaries(values.id, updateBeneficiariesPayload)

    this.setState({ dirty: true })
  }

  handleBeneficiaryAmountChange = (beneficiaryIndex: number, e: any): void => {
    const { setFieldValue } = this.props
    const beneficiary = this.getBeneficiaries().find((v: any, i: any) => i === beneficiaryIndex)

    setFieldValue(`beneficiaries[${beneficiaryIndex}]`, {
      ...beneficiary,
      beneficiary: {
        ...beneficiary.beneficiary,
        value: {
          ...beneficiary.beneficiary.value,
          amountToPay: e.target.value || 0,
        },
      },
    })

    this.setState({ dirty: true })
  }

  handleDepositManagedByChange = (beneficiaryIndex: number, option: any): void => {
    const {
      setFieldValue,
      updateBeneficiaries,
      values,
      getOwnerAccountFromActiveInvoiceId,
      currentAgencyId,
      getInvoiceTypeByValue,
    } = this.props
    const beneficiary = this.getBeneficiaries().find((v: any, i: any) => i === beneficiaryIndex)
    const ownerAccount = getOwnerAccountFromActiveInvoiceId(values?.id)
    const invoiceTypeObj = getInvoiceTypeByValue(values.invoiceType || '')
    const invoiceTypeName = pathOr('', ['name'], invoiceTypeObj)

    const transformedBeneficiary = transformDepositBeneficiaryForManagedByChange(
      beneficiary,
      option.value,
      currentAgencyId,
      values?.customerName,
      invoiceTypeName,
      ownerAccount?.partyId,
    )
    setFieldValue(`beneficiaries[${beneficiaryIndex}]`, transformedBeneficiary)
    updateBeneficiaries(values.id, [transformedBeneficiary])
  }

  handleBeneficiaryReferenceChange = (beneficiaryIndex: number, reference: any, type: any): void => {
    const { fetchEasyPayData, easyPayData, setFieldValue } = this.props

    if (type === 'EasyPayBeneficiary') {
      const easyPayReferenceData = pathOr(null, [reference], easyPayData)
      const isEasyPayReferenceDataValid = pathOr(false, ['request', 'success'], easyPayReferenceData)

      if (!easyPayReferenceData || !isEasyPayReferenceDataValid) {
        this.setState({
          fetchingEasyPayData: true,
          currentBeneficiaryIndex: beneficiaryIndex,
          currentBeneficiaryReference: reference,
          currentBeneficiaryType: type,
        })
        fetchEasyPayData(reference)
      } else {
        this.updateBeneficiaryReference(beneficiaryIndex, reference, type)
      }
    } else {
      this.updateBeneficiaryReference(beneficiaryIndex, reference, type)
    }

    const beneficiary = this.getBeneficiaries().find((v: any, i: any) => i === beneficiaryIndex)

    setFieldValue(`beneficiaries[${beneficiaryIndex}]`, {
      ...beneficiary,
      beneficiary: {
        ...beneficiary.beneficiary,
        value: {
          ...beneficiary.beneficiary.value,
          ...(type !== 'EasyPayBeneficiary' ? { reference } : {}),
          ...(type === 'EasyPayBeneficiary' ? { easyPayReference: reference } : {}),
        },
      },
    })
  }

  updateBeneficiaryReference = (beneficiaryIndex: number, reference: any, type: any): void => {
    const { values, updateBeneficiaries } = this.props

    const updatedBeneficiaries = values.beneficiaries.map((b: any, i: any) => {
      return {
        ...b,
        beneficiary: {
          ...b.beneficiary,
          value: {
            ...b.beneficiary.value,
            ...(type !== 'EasyPayBeneficiary' && i === beneficiaryIndex ? { reference } : {}),
            ...(type === 'EasyPayBeneficiary' && i === beneficiaryIndex ? { easyPayReference: reference } : {}),
          },
        },
      }
    })

    updateBeneficiaries(values.id, updatedBeneficiaries)
  }

  handleDepositBeneficiaryTransferChange = (index: number, transfer: any): void => {
    const {
      setFieldValue,
      values,
      updateBeneficiaries,
      currentAgencyId,
      getOwnerAccountFromActiveInvoiceId,
      getInvoiceTypeByValue,
    } = this.props
    const beneficiary = this.getBeneficiaries().find((v: any, i: any) => i === index)
    const ownerAccount = getOwnerAccountFromActiveInvoiceId(values?.id)
    const invoiceTypeObj = getInvoiceTypeByValue(values.invoiceType || '')
    const invoiceTypeName = pathOr('', ['name'], invoiceTypeObj)

    const transformedBeneficiary = transformDepositBeneficiaryForTransferChange(
      beneficiary,
      transfer,
      currentAgencyId,
      values?.customerName,
      invoiceTypeName,
      ownerAccount?.partyId,
    )
    setFieldValue(`beneficiaries[${index}]`, transformedBeneficiary)
    updateBeneficiaries(values.id, [transformedBeneficiary])
  }

  handleBeneficiaryOrderChange = (newOrder: any): void => {
    const { values, updateBeneficiaries } = this.props
    const updatedBeneficiaries = newOrder.map((o: any) => this.getBeneficiaries()[o])
    updateBeneficiaries(values.id, updatedBeneficiaries)
    this.setState({ beneficiariesHash: hashCode(JSON.stringify(updatedBeneficiaries)) })
  }

  handleBeneficiaryRemoved = (index: number): void => {
    const newBeneficiaries = arrayUtils.removeByIndex(index, this.getBeneficiaries())
    this.props.setFieldValue('beneficiaries', newBeneficiaries)
    this.setState({ dirty: true })
    this.handleSave(newBeneficiaries)
  }

  handleVatChange = (index: number, vatApplied: any): void => {
    const beneficiary = this.getBeneficiaries().find((v: any, i: any) => i === index)
    this.props.setFieldValue(`beneficiaries[${index}]`, {
      ...beneficiary,
      beneficiary: {
        ...beneficiary.beneficiary,
        value: {
          ...beneficiary.beneficiary.value,
          vat: vatApplied,
        },
      },
    })
  }

  customerHasFunds = (): boolean => {
    return parseFloat(this.props.values.customerAvailableFunds) > 0
  }

  getBeneficiaryPartyErrors(): $TSFixMe {
    const { fetchPartiesErrors } = this.props
    const beneficiaries = this.getBeneficiariesForPaymentAllocation()

    const beneficiaryPartyIds = pipe(
      filter((b: any) => path(['beneficiary', 'type'], b) === 'PartyBeneficiary'),
      rMap((b: any) => pathOr('', ['beneficiary', 'value', 'partyId'], b)),
      filter((b: any) => b),
      join(','),
    )(beneficiaries)

    fetchPartiesErrors(beneficiaryPartyIds)
  }

  updateSubmissionProgress = (onComplete: any): $TSFixMe => {
    this.getBeneficiaryPartyErrors()

    return interval(100, animationFrameScheduler)
      .pipe(
        map(i => i * 5),
        takeWhile(i => i <= 100),
        takeWhile(() => !this.cancelPaymentApproval),
      )
      .subscribe(i => this.setState({ submissionProgress: i }), null, onComplete)
  }

  handleSubmitForm(submitForm: any): $TSFixMe {
    this.updateSubmissionProgress(() => {
      if (this.cancelPaymentApproval) {
        this.setState({ submissionProgress: 0 })
        this.cancelPaymentApproval = false
      } else {
        const beneficiaries = this.getBeneficiariesForPaymentAllocation()
        const preValidationErrors = pipe(
          rMap((b: any) => pathOr([], ['beneficiary', 'errors'], b)),
          flatten,
        )(beneficiaries)

        this.setState({ submissionProgress: 0, dirty: false })

        if (preValidationErrors.length === 0) {
          submitForm()
        }
      }
    })
  }

  handleNudge = (): void => {
    const { nudge, currentInvoice } = this.props
    nudge([currentInvoice.id])
  }

  getBeneficiariesSum = (): number => {
    const {
      values: { beneficiaries },
    } = this.props

    return sum(
      beneficiaries.map(
        ({
          beneficiary: {
            value: { amount },
          },
        }: any) => (isNaN(parseInt(amount)) ? 0 : parseFloat(amount)),
      ),
    )
  }

  getBeneficiariesAmountToPaySum = (): number => {
    const {
      values: { beneficiaries },
    } = this.props

    return sum(
      beneficiaries.map(
        ({
          beneficiary: {
            value: { amountToPay },
          },
        }: any) => (isNaN(parseInt(amountToPay)) ? 0 : parseFloat(amountToPay)),
      ),
    )
  }

  getBeneficiariesBalanceSum = (): number =>
    pipe(pathOr([], ['beneficiaries']), rMap(prop('balance')), sum)(this.props.values)

  getBeneficiariesAllocationsSum = (): number =>
    pipe(pathOr([], ['beneficiaries']), rMap(prop('originalAmount')), sum)(this.props.values)

  getBeneficiariesTotalAmountToPay = (): number =>
    sumBy(this.props.values.beneficiaries, (b: any) => b.beneficiary.value.amountToPay)

  getCreditNotesSum = (): number => pipe(pathOr([], ['creditNotes']), rMap(prop('amount')), sum)(this.props.values)

  getUnallocatedAmount = (): number => {
    const balance = pathOr(0, ['values', 'balance'], this.props)
    return balance - this.getBeneficiariesBalanceSum()
  }

  areAllFundsAllocated = (): boolean => this.getUnallocatedAmount() === 0

  getBalanceProgress = (): number => {
    const { values } = this.props

    switch (values.invoiceStatus) {
      case 'Overdue':
      case 'Short':
      case 'NotSet':
        return this.areAllFundsAllocated()
          ? 100 - ((values.grossAmount - values.balance) / values.grossAmount) * 100
          : 100 - ((values.grossAmount - this.getBeneficiariesAllocationsSum()) / values.grossAmount) * 100
      case 'Ready':
        return 100 - ((values.balance - this.getBeneficiariesAllocationsSum()) / values.balance) * 100
      default:
        return 0
    }
  }

  buildFundDistributionFooter = (): $TSFixMe => {
    const { values } = this.props
    return (
      <FundDistributionFooter
        text={this.areAllFundsAllocated() ? 'Amount to approve' : 'Amount unallocated'}
        amount={this.areAllFundsAllocated() ? this.getBeneficiariesTotalAmountToPay() : this.getUnallocatedAmount()}
        isComplete={
          (values.invoiceStatus === 'Ready' || values.invoiceStatus === 'ready to go') && this.areAllFundsAllocated()
        }
        progress={{
          value: this.getBalanceProgress(),
          style:
            values.invoiceStatus === 'Overdue'
              ? 'error'
              : values.invoiceStatus === 'Short' || values.invoiceStatus === 'NotSet'
              ? 'warning'
              : 'default',
        }}
      />
    )
  }

  render(): null | React.ReactElement {
    const {
      currentInvoice: invoice,
      getInvoiceTypeByValue,
      handleSubmit,
      values,
      submitForm,
      canInvoiceBeNudged,
      getLastNudgeTime,
      partyTags,
      errors,
      userRole,
      isActiveInvoiceViewLoading,
      isReadOnly,
    } = this.props

    const isLoaded = !isEmpty(values) || invoice

    const styleNames = classNames('root')

    const invoiceTypeObj = getInvoiceTypeByValue(values.invoiceType || '')
    const invoiceTypeName = pathOr('', ['name'], invoiceTypeObj)

    const creditNotes = values.creditNotes || []

    return (
      <div className={styleNames}>
        {isLoaded && (
          <Helmet>
            <title>
              reOS | Invoices | Active | {`${values.customerName as string} - ${values.invoiceType as string}`}
            </title>
          </Helmet>
        )}
        <InvoiceCard
          invoice={{ ...invoice, invoiceNumber: `INV-${pathOr('', ['invoiceNumber'], invoice) as string}` }}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; invoice: any; contextua... Remove this comment to see the full error message
          contextualMenuOptions={[]}
          onClose={this.handleClose}
          headerBefore={
            !isEmpty(creditNotes) ? (
              <>
                <Button className={styles['snapshot-button']} size="xs" ghost onClick={this.openSnapshot}>
                  More info
                </Button>
                <Button className={styles['credit-notes']} size="xs" ghost onClick={this.scrollToCreditNotes}>
                  Credit Notes
                </Button>
              </>
            ) : (
              <Button className={styles['snapshot-button']} size="xs" ghost onClick={this.openSnapshot}>
                More info
              </Button>
            )
          }
        >
          {/** @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; loading: any; style: { ... Remove this comment to see the full error message */}
          <DimmerLoader loading={isActiveInvoiceViewLoading} style={{ minHeight: '300px' }}>
            <section className={styles['invoice-data']}>
              <form onSubmit={handleSubmit}>
                <fieldset disabled={isReadOnly}>
                  {isLoaded && invoice && (
                    <>
                      <Segment.Group>
                        {values.invoiceStatus === 'Overdue' && (
                          <div className={styles['label-overdue']}>
                            <Label size="md">Overdue</Label>
                          </div>
                        )}
                        {/** @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; horizontal: true; onC... Remove this comment to see the full error message */}
                        <Segment horizontal onClick={this.openSnapshot} style={{ flexWrap: 'nowrap' }}>
                          <Segment.Label
                            icon={<Accounting />}
                            text={invoiceTypeName}
                            secondaryText={
                              typeof values.dueDate !== 'undefined'
                                ? `Due ${dateUtils.timeAgo(new Date(values.dueDate))}`
                                : ''
                            }
                          />
                          <div className={styles.amount}>
                            <CurrencyField
                              disabled
                              includeVatControl
                              vatApplied={values.vatAmount > 0}
                              name="amount"
                              placeholder="Enter amount"
                              value={values.grossAmount}
                            />
                          </div>
                        </Segment>
                        {values.description && (
                          <Segment horizontal>
                            <TextField
                              inputComponent={
                                <TextInput type={TextFieldTypes.text} value={values.description} disabled />
                              }
                              label="Description"
                            />
                          </Segment>
                        )}
                      </Segment.Group>

                      {!isActiveInvoiceViewLoading && (
                        <PaymentAllocation
                          errors={this.getPaymentAllocationErrors()}
                          invoiceType={getInvoiceTypeByValue(values.invoiceType)}
                          invoiceStatus={values.invoiceStatus}
                          balance={values.balance}
                          grossAmount={values.grossAmount}
                          availableFunds={values.customerAvailableFunds || 0}
                          beneficiaries={this.getBeneficiariesForPaymentAllocation()}
                          onNewBeneficiaryAdded={this.handleNewBeneficiary}
                          onNewBeneficiaryAmountChange={this.handleBeneficiaryAmountChange}
                          onDepositManagedByChange={this.handleDepositManagedByChange}
                          onBeneficiaryReferenceChange={this.handleBeneficiaryReferenceChange}
                          onDepositBeneficiaryTransferChange={this.handleDepositBeneficiaryTransferChange}
                          onBeneficiariesOrderChange={this.handleBeneficiaryOrderChange}
                          onBeneficiaryRemoved={this.handleBeneficiaryRemoved}
                          onVatChange={this.handleVatChange}
                          partyTags={partyTags}
                          beneficiariesKey={this.state.beneficiariesHash}
                          Footer={this.buildFundDistributionFooter}
                          inputTooltipText="Amount to pay this beneficiary"
                          unallocatedAmount={this.getUnallocatedAmount()}
                          portfolioId={values.portfolioId}
                        />
                      )}

                      <Segment.Group>
                        <Segment horizontal>
                          <Segment.Label text="Credit notes" />
                          <CreditNoteContainer>
                            <div id="creditNotes"></div>
                            <CreditNoteButton className={styles['credit-note-button']} invoiceId={invoice.id} />
                            <CreditNoteModal />
                          </CreditNoteContainer>
                        </Segment>
                        <CreditNotesList creditNotes={creditNotes} />
                      </Segment.Group>

                      <Segment.Group>
                        <Segment horizontal state={values.invoiceStatus === 'Overdue' ? 'danger' : 'default'}>
                          <div className={styles['overdue-actions']}>
                            {(values.invoiceStatus === 'Overdue' || values.invoiceStatus === 'Short') && (
                              <>
                                <span style={{ width: '100%' }}>
                                  You can only nudge once every 24 hours.
                                  {!canInvoiceBeNudged(invoice.id) &&
                                    ` Last nudge sent on ${getLastNudgeTime(invoice.id)}`}
                                </span>
                                <NudgeButton
                                  canInvoiceBeNudged={canInvoiceBeNudged(invoice.id)}
                                  handleNudge={this.handleNudge}
                                />
                                {/* <Button size='sm' secondary>Send notice</Button> */}
                              </>
                            )}
                          </div>
                        </Segment>
                      </Segment.Group>
                    </>
                  )}
                </fieldset>

                {isLoaded && invoice && (
                  <>
                    {invoice?.id && (
                      <Segment.Group>
                        <FileTable
                          className={styles.documents}
                          showDateTime={true}
                          showDateTimeBeneath={true}
                          allowRenaming={!isReadOnly}
                          allowRemove={!isReadOnly}
                          displayAndEditExtension={false}
                          onError={() => null}
                          // @ts-expect-error
                          forOwner={{ className: 'invoice', classId: invoice.id }}
                        />
                      </Segment.Group>
                    )}

                    {/* <Segment.Group>
                        <Segment>
                          <Segment.Label text='Notes' />
                          <TextField textarea name='notes' />
                          <Checkbox
                            name='notes-private'
                            label='Private'
                            // onChange={() => { }}
                          />
                        </Segment>
                      </Segment.Group> */}

                    <footer>
                      <div className={styles['form-footer']}>
                        <div className={styles['action-buttons']}>
                          <DownloadInvoicePdfButton invoiceId={invoice.id} invoiceNumber={invoice.invoiceNumber} />
                          {userRole === 'Owner' && (
                            <>
                              {this.state.submissionProgress > 0 ? (
                                <Button
                                  secondary
                                  onClick={() => {
                                    this.cancelPaymentApproval = true
                                  }}
                                >
                                  Cancel
                                </Button>
                              ) : (
                                <Button
                                  type="button"
                                  onClick={() => this.handleSubmitForm(submitForm)}
                                  disabled={
                                    !this.customerHasFunds() || !isEmpty(errors) || this.sumBeneficiaryAmounts() === 0
                                  }
                                  ghost={!this.customerHasFunds()}
                                >
                                  {this.customerHasFunds() ? 'Approve payments' : 'Insufficient funds'}
                                </Button>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <LinearProgress percent={this.state.submissionProgress} strokeColor="#128269" strokeWidth={0.8} />
                    </footer>
                  </>
                )}
              </form>
            </section>
          </DimmerLoader>
        </InvoiceCard>
      </div>
    )
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
ViewActiveInvoice.propTypes = propTypes

export default subscribe(
  [InvoicesProvider, LayoutContext],
  (
    {
      currentInvoice,
      // toggleEdit,
      getInvoiceTypeByValue,
      closeInvoice,
      createPayment,
      nudge,
      canInvoiceBeNudged,
      getLastNudgeTime,
      updateBeneficiaries,
      partyTags,
      agencyBankDetails,
      getPartyBankDetails,
      getPartyNameById,
      userRole,
      fetchPortfolio,
      isActiveInvoiceViewLoading,
      getBankingPartyById,
      fetchPartiesErrors,
      fetchEasyPayData,
      easyPayData,
      viewingInvoice,
      isReadOnly,
      currentAgencyId,
      getOwnerAccountFromActiveInvoiceId,
      getPartyAccount,
      getCurrentAgencyGlobalVatEnabled,
    }: any,
    { secondaryPanel }: any,
  ) => ({
    currentInvoice,
    // toggleEdit,
    getInvoiceTypeByValue,
    closeInvoice,
    createPayment,
    nudge,
    canInvoiceBeNudged,
    getLastNudgeTime,
    updateBeneficiaries,
    partyTags,
    agencyBankDetails,
    getPartyBankDetails,
    getPartyNameById,
    userRole,
    fetchPortfolio,
    isActiveInvoiceViewLoading,
    secondaryPanel,
    getBankingPartyById,
    fetchPartiesErrors,
    fetchEasyPayData,
    easyPayData,
    viewingInvoice,
    isReadOnly,
    currentAgencyId,
    getOwnerAccountFromActiveInvoiceId,
    getPartyAccount,
    getCurrentAgencyGlobalVatEnabled,
  }),
)(
  withFormik({
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    mapPropsToValues: props => {
      const invoice = get(props, 'currentInvoice', {})
      const beneficiaries = get(invoice, 'beneficiaries', [])

      return {
        ...invoice,
        /**
         * Add availableFunds to beneficiaries because yup doesn't support accessing parents higher than 1 level
         * @link https://github.com/jquense/yup/issues/225#issuecomment-404910170
         */
        beneficiaries: beneficiaries.map((b: any) => ({
          ...b,
          originalAmount: b.beneficiary.value.amount,
          // Add to beneficiary for validation purposes. Validation object doesn't have access to outer scope
          availableFunds: get(invoice, 'customerAvailableFunds', 0),
          // Add to beneficiary for validation purposes. Validation object doesn't have access to outer scope
          invoiceBalance: get(invoice, 'balance', 0),
          beneficiary: {
            ...b.beneficiary,
            value: {
              ...b.beneficiary.value,
              balance: b.balance,
              amountToPay: b.balance,
            },
          },
        })),
      }
    },
    handleSubmit: (values, { props, setSubmitting }) => {
      const { id, beneficiaries } = values
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      props.createPayment(
        id,
        beneficiaries.map(({ beneficiary }: any) => ({
          beneficiaryId: beneficiary.value.id,
          amount: beneficiary.value.amountToPay,
        })),
      )

      const isOutstandingAmountPaid =
        sumBy(beneficiaries, (b: any) => parseFloat(get(b, 'balance', 0)) || 0) ===
        sumBy(beneficiaries, (b: any) => parseFloat(get(b, 'beneficiary.value.amount', 0)) || 0)

      if (isOutstandingAmountPaid) {
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        props.closeInvoice(values.id, 'active')
      }
    },
  })(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof ViewActiveInvoice' is not a... Remove this comment to see the full error message
    ViewActiveInvoice,
  ),
)
