import React from 'react'
import { useSelector } from 'react-redux'
import { $TSFixMe } from 'types/ts-migrate'
import { TextFieldTypes } from 'views/components/atoms/TextField/text-field.types'
import { Button, Column, FormLoader, Grid, TextField, TextInput } from '../../..'
import { apiStateSelectors } from '../../../../../modules/api/apiState'
import { propertyApiEvents } from '../../../../../modules/api/property'
import styles from './PropertyForm.module.scss'

// eslint-disable-next-line react/display-name
const withButtonContainer =
  ({ clearSelection, isSubmitting }: $TSFixMe): $TSFixMe =>
  // eslint-disable-next-line react/display-name
  ({ children }: any): React.ReactElement =>
    (
      <div className={styles.footer}>
        {children}
        {!isSubmitting && (
          <Button style={{ marginLeft: '16px' }} size="sm" secondary onClick={clearSelection}>
            Change property address
          </Button>
        )}
        {/* <Button size='sm' secondary>Change property address</Button> */}
      </div>
    )

const PropertyPreview = ({
  values,
  clearSelection,
  handleConfirmation,
  isActivePortfolio,
}: any): React.ReactElement => {
  /**
   * @todo add portfolio creation here as well. Not available in apiState because of forkEpic
   * @link https://github.com/CastleOne/robin-client/blob/557e06304c5a211ebc7688757111b48bd7ae2505/src/modules/ui/ChatBot/portfolioEpics.js#L37
   */
  const isSubmitting = useSelector(state =>
    apiStateSelectors.isLoading(state)([propertyApiEvents.createProperty_request]),
  )
  const formLoaderState = isSubmitting && 'submitting'

  return (
    <div className={styles.root}>
      {/* <form className={styles.form} onSubmit={handleConfirmation}> */}
      {/** @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; className: any; onSubmi... Remove this comment to see the full error message */}
      <FormLoader
        className={styles.form}
        onSubmit={handleConfirmation}
        state={formLoaderState}
        buttonProps={{ children: isActivePortfolio ? 'Save' : 'Yes, looks good' }}
        ButtonContainer={withButtonContainer({ clearSelection, isSubmitting })}
      >
        <div className={styles.body}>
          <Grid>
            <Column xs={12} md={8} flexOptions="padding-r-1">
              <TextField
                inputComponent={
                  <TextInput
                    type={TextFieldTypes.text}
                    name="buildingName"
                    placeholder="e.g. Cardiff Castle"
                    value={values.buildingName}
                    disabled
                  />
                }
                label="Building / Complex name (if applicable)"
              />
            </Column>
            {/** @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; xs: number; lg: number;... Remove this comment to see the full error message */}
            <Column xs={12} lg={4} flexOptions="padding-r-1">
              <TextField
                inputComponent={
                  <TextInput
                    type={TextFieldTypes.text}
                    name="unitNumber"
                    placeholder="e.g. 4"
                    value={values.unitNumber}
                    disabled
                  />
                }
                label="Unit Number (if applicable)"
              />
            </Column>
            <Column xs={12} md={8} flexOptions="padding-r-1">
              <TextField
                inputComponent={
                  <TextInput type={TextFieldTypes.text} name="streetAddress" value={values.streetAddress} disabled />
                }
                label="Street Address"
              />
            </Column>
          </Grid>
          <Grid>
            <Column xs={12} md={4} flexOptions="padding-r-1">
              <TextField
                inputComponent={<TextInput type={TextFieldTypes.text} name="city" value={values.city} disabled />}
                label="City / municipality"
              />
            </Column>
            <Column xs={12} md={4} flexOptions="padding-r-1">
              <TextField
                inputComponent={
                  <TextInput type={TextFieldTypes.text} name="province" value={values.province} disabled />
                }
                label="Province"
              />
            </Column>
            <Column xs={12} md={4} flexOptions="padding-r-1">
              <TextField
                inputComponent={
                  <TextInput type={TextFieldTypes.text} name="postalCode" value={values.postalCode} disabled />
                }
                label="Postal code"
              />
            </Column>
          </Grid>
          <Grid>
            <Column xs={12} md={4} flexOptions="padding-r-1">
              <TextField
                inputComponent={<TextInput type={TextFieldTypes.text} name="country" value={values.country} disabled />}
                label="Country"
              />
            </Column>
          </Grid>
        </div>
      </FormLoader>
    </div>
  )
}

export default PropertyPreview
