import React from 'react'
import { createSelector } from 'reselect'
import { get } from 'lodash-es'
import { keys, map, pipe, uniqBy, flatten } from 'ramda'
import { format } from 'date-fns'
import { partyApiSelectors } from '../../api/party'
import { NAMESPACE } from '../constants'

const getState = (state: any) => state

export const contactsState = createSelector(getState, s => get(s, [NAMESPACE]))

export const getSearchQuery = createSelector(contactsState, s => get(s, 'searchQuery'))

export const depositTransferModal = createSelector(contactsState, s => get(s, 'depositTransferModal'))

export const getPartyDataForContactsTable = createSelector(getState, state => (id: any) => ({
  id,
  name: partyApiSelectors.getPartyNameById(state)(id),
  lastName: partyApiSelectors.getPartyLastNameById(state)(id),
  email: partyApiSelectors.getPartyEmailById(state)(id),
  contactNumber: partyApiSelectors.getPartyTelNumberById(state)(id),
  createdAt: partyApiSelectors.getPartyPropertyById(state)(id, 'createdAt'),
  tags: partyApiSelectors.getPartyTagsById(state)(id),
  partyType: partyApiSelectors.getPartyTypeById(state)(id),
  accounts: partyApiSelectors.getPartyAccountsById(state)(id),
}))

export const contactsFilters = createSelector(getState, state => {
  const parties = partyApiSelectors.getParties(state)
  const contacts = pipe(
    keys,
    map((id: any) => getPartyDataForContactsTable(state)(id)),
  )(parties)

  const filterContactsByPartyTag = (tag: any) =>
    contacts.filter((contact: any) => contact.tags.filter((t: any) => t.includes(tag)).length > 0)

  const filterContactsByAccountTag = (tag: any) =>
    contacts.filter((contact: any) => contact.accounts.filter((account: any) => account.tag.includes(tag)).length > 0)

  const filterContactsByPartyTagAndAccountTag = (...tags: any[]) =>
    pipe(
      map((tag: any) =>
        uniqBy((p: any) => p.id, [...filterContactsByPartyTag(tag), ...filterContactsByAccountTag(tag)]),
      ),
      flatten,
      uniqBy((p: any) => p.id),
    )(tags)

  const filterContactsByTag = (tag: any) => {
    switch (tag) {
      case 'Tenant':
      case 'Owner':
        return filterContactsByPartyTagAndAccountTag(tag)
      case 'Team':
        return filterContactsByPartyTagAndAccountTag('Agent')
      case 'Supplier':
        return filterContactsByPartyTagAndAccountTag('Supplier', 'Contractor')
      case 'Institution':
        return filterContactsByPartyTagAndAccountTag('Municipality', 'Rates', 'BodyCorporate', 'Levies')
      default:
        return []
    }
  }

  const tenants = filterContactsByTag('Tenant')
  const owners = filterContactsByTag('Owner')
  const suppliers = filterContactsByTag('Supplier')
  const team = filterContactsByTag('Team')
  const institutions = filterContactsByTag('Institution')
  const incompleteDetails = contacts.filter((c: any) => !partyApiSelectors.partyHasBankDetails(state)(c.id))

  return [
    {
      key: 'all',
      label: 'All',
      contacts: contacts,
      count: contacts.length,
    },
    {
      key: 'tenants',
      label: 'Tenants',
      contacts: tenants,
      count: tenants.length,
    },
    {
      key: 'owners',
      label: 'Owners',
      contacts: owners,
      count: owners.length,
    },
    {
      key: 'suppliers',
      label: 'Suppliers',
      contacts: suppliers,
      count: suppliers.length,
    },
    {
      key: 'team',
      label: 'Team',
      contacts: team,
      count: team.length,
    },
    {
      key: 'institutions',
      label: 'Institutions',
      contacts: institutions,
      count: institutions.length,
    },
    {
      key: 'incompleteDetails',
      label: 'Incomplete Details',
      contacts: incompleteDetails,
      count: incompleteDetails.length,
    },
  ]
})

// eslint-disable-next-line react/display-name
export const getContactInfo = createSelector(getState, state => (id: any) => {
  const party = partyApiSelectors.getPartyDetailsById(state)(id)
  const name = partyApiSelectors.getPartyNameById(state)(id)
  const email = partyApiSelectors.getPartyEmailById(state)(id)
  const contactNumber = partyApiSelectors.getPartyTelNumberById(state)(id)
  const lastModified = partyApiSelectors.getPartyUpdatedAt(state)(id)
  const bank = get(party, 'bank')
  const accountType = get(party, 'acccountType')
  const accountNumber = get(party, 'accountNumber')
  const address = partyApiSelectors.getPartyAddressById(state)(id)

  return {
    name,
    lastModified: lastModified && format(lastModified, 'dd-MM-yyyy hh:mm'),
    email,
    contactNumber,
    bank: bank && (
      <p>
        {bank} <br />
        <span>{accountType}</span>
      </p>
    ),
    accountNumber,
    address,
  }
})

export const getInitialValuesForEdit = createSelector(getState, state => (id: any) => {
  const details = partyApiSelectors.getPartyDetailsById(state)(id)
  return {
    ...details,
    passport: details.passport ? details.passport : '',
    dateOfBirth: details.dateOfBirth ? details.dateOfBirth : '',
    buildingName: '',
    unitNumber: '',
    streetAddress: '',
    municipality: '',
    postalCode: '',
    country: '',
  }
})
